import createPersistedState from 'vuex-persistedstate'

import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './modules/authentication'
import companies from './modules/companies'
import sedes from './modules/sedes'
import offices from './modules/offices'
import categories from './modules/categories'
import capacities from './modules/capacities'
import colors from './modules/colors'
import brands from './modules/brands'
import types from './modules/types'
import plans from './modules/plans'
import customers from './modules/customers'
import documents from './modules/documents'
import suppliers from './modules/suppliers'
import employees from './modules/employees'
import products from './modules/products'
import purchases from './modules/purchases'
import inventories from './modules/inventories'
import transfers from './modules/transfers'
import saleoptions from './modules/saleoptions'
import sales from './modules/sales'
import roles from './modules/roles';
import users from './modules/users';
import tills from './modules/tills';
import notifications from './modules/notifications';
import imeis from './modules/imeis';
import reportTransfers from './modules/report-transfers';
import reportSales from './modules/report-sales';
import vouchers from './modules/vouchers';
import banks from './modules/banks';
import accounts from './modules/accounts';
import reportPurchases from './modules/report-purchases';
import reportTills from './modules/report-tills';
import units from './modules/units';
import debts from './modules/debts';
import supplierAccounts from './modules/supplier-accounts';
import orders from './modules/orders';
import creditNotes from './modules/credit-notes';
import userSessions from './modules/user-sessions';
import storehouses from './modules/storehouses';
import services from './modules/services';
import stations from './modules/stations';
import commissions from './modules/commissions';
import ReportKardex from './modules/report-kardex';
import ReportProducts from './modules/report-products';
import ReportServices from './modules/report-services';
import Areas from './modules/areas';

import dashboard from './modules/dashboard';

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    state: {

    },
    modules: {
        accounts,
        areas: Areas,
        authentication,
        banks,
        brands,
        capacities,
        categories,
        colors,
        commissions,
        companies,
        "credit-notes": creditNotes,
        customers,
        debts,
        documents,
        employees,
        imeis,
        inventories,
        notifications,
        offices,
        orders,
        plans,
        products,
        purchases,
        "report-kardex": ReportKardex,
        "report-products": ReportProducts,
        "report-purchases": reportPurchases,
        "report-sales": reportSales,
        "report-services": ReportServices,
        "report-tills": reportTills,
        "report-transfers": reportTransfers,
        roles,
        sales,
        saleoptions,
        sedes,
        services,
        stations,
        storehouses,
        suppliers,
        "supplier-accounts": supplierAccounts,
        tills,
        transfers,
        types,
        units,
        users,
        "user-sessions": userSessions,
        vouchers,
        dashboard
    },
    mutations: {

    },
    actions: {

    },
    plugins: [
        createPersistedState(),
    ]
})