import StorehousesApi from '../../apis/Storehouses';

export default {
    namespaced: true,
    state: {
        storehouses: []
    },
    mutations: {
        set_storehouses (state, data) {
            state.storehouses = data;
        }
    },
    actions: {
        async getStorehouses({commit, dispatch}, filters) {
            return await StorehousesApi.get_all(filters)
                .then( response => {
                    commit('set_storehouses', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar almacenes, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}