import Api from './Api'

const END_POINT = 'areas'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(areas) {
        return await Api.post(`${END_POINT}`, areas)
    },
    async update(areas) {
        return await Api.patch(`${END_POINT}/${areas.id}`, areas)
    },
    async change_status(areas) {
        return await Api.patch(`${END_POINT}/${areas.id}/change-status`, areas)
    },
    async delete(areas) {
        return await Api.delete(`${END_POINT}/${areas.id}`, areas)
    }
}