import Api from './Api';

const END_POINT = 'orders';

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'orders-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
        //return await Api.get(`${END_POINT}`, request);
    },
    async store(order) {
        return await Api.post(`${END_POINT}`, order);
    },
    async storeSale(sale) {
        return await Api.post(`${END_POINT}/dispatch`, sale);
    },
    async cancel(order) {
        return await Api.post(`${END_POINT}/cancel`, order);
    },
    async download_detail(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/export-excel`, request);
    },
    async addDetail(item) {
        return await Api.post(`${END_POINT}/add-detail`, item);
    },
    async updateDetail(item) {
        return await Api.post(`${END_POINT}/update-detail`, item);
    },
    async deleteDetail(item) {
        return await Api.post(`${END_POINT}/delete-detail`, item);
    },
    async download_by_seller(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/export-by-seller`, request);
    },
    async downloadEmbutidos(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/export-embutidos`, request);
    },
}