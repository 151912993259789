export default {
    namespaced: true,
    state: {
        notification: null,
        show_notification: false,
        class_notification: []
    },
    mutations: {
        set_notification(state, data){
            state.notification = data;
        },
        set_show_notification(state, value) {
            state.show_notification = value;
        },
        set_class_notification(state, data) {
            state.class_notification = data;
        },
        add_class_notification(state, value) {
            state.class_notification.push(value);
        },    
    },
    actions: {
        empty_notification({commit}){
            commit('set_notification', null);
            commit('set_show_notification', false);           
        },
        store_notification({commit}, data) {
            commit('set_notification', data);
            commit('set_show_notification', true);
        },
        can_show_notification({commit}, data) {
            commit('set_show_notification', data);
        }
    }
}