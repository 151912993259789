import EmployeesApi from '../../apis/Employees'

export default {
    namespaced: true,
    state: {
        employees: [],
        workers: [],
        paginated:{},
        filtersPaginated:{
            dni: null,
            first_name: null,
            last_name: null,
            birthday: null,
            email: null,
            phone: null,
            username: null,
            password: null,
            offices: []
        }
    },
    mutations: {
        set_filter_paginated(state,filters){
            state.filtersPaginated=filters
        },
        set_paginated(state,paginated){state.paginated=paginated},
        set_employees(state, employees){
            state.employees = employees
        },
        set_workers (state, data) {
            state.workers = data;
        }
    },
    actions: {
        changeFilterPaginated({commit, dispatch}, filters) {
            // console.log(filters)
            commit('set_filter_paginated', filters)
        },
        async getEmployees({commit, dispatch}, filters) {
            return await EmployeesApi.get_all(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage!=-1){
                                        commit('set_employees', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_employees', dataPaginated)
                                        commit('set_paginated', {})
                                    }

                                } else {
                                    commit('set_employees', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_employees', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_employees', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, employee) {
            return await EmployeesApi.store(employee)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getEmployees')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, employee) {
            return await EmployeesApi.update(employee)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getEmployees')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, employee) {
            let action = (employee.active) ? 'inactivación' : 'activación';
            return await EmployeesApi.change_status(employee)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getEmployees')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, employee) {
            return await EmployeesApi.delete(employee)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getEmployees')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async getWorkers({commit, dispatch}, filters) {
            return await EmployeesApi.get_worker(filters)
                .then( response => {
                    commit('set_workers', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar trabajadores, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}