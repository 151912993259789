import Api from './Api'

const END_POINT = 'customers'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'customers-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
    },
    async store(customer) {
        return await Api.post(`${END_POINT}`, customer)
    },
    async update(customer) {
        return await Api.patch(`${END_POINT}/${customer.id}`, customer)
    },
    async change_status(customer) {
        return await Api.patch(`${END_POINT}/${customer.id}/change-status`, customer)
    },
    async delete(customer) {
        return await Api.delete(`${END_POINT}/${customer.id}`, customer)
    },
    async search_document(document) {
        return await Api.get(`https://api.sanarlabs.com/api/customers/${document}/search-document`);
    },
    download(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/download-customers`, request);
    },
}