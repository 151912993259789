import AccountsApi from '../../apis/Accounts';

export default {
    namespaced: true,
    state: {
        accounts: [],
        account: null,
        simbol_currency: 'S/',
        movements: [],
        account_pay_card: null
    },
    mutations: {
        set_accounts (state, data) {
            state.accounts = data
        },
        set_account (state, data) {
            state.account = data
        },
        set_simbol_currency (state, value) {
            state.simbol_currency = value;
        },
        set_movements (state, data) {
            state.movements = data;
        },
        set_account_pay_card (state, data) {
            state.account_pay_card = data;
        }
    },
    actions: {
        selectAccount ({commit}, data) {
            commit('set_account', data);
            if (data) {
                if (data.currency === 'DOLARES') {
                    commit('set_simbol_currency', '$');
                } else {
                    commit('set_simbol_currency', 'S/');
                }
            }
        },
        getAccountPayCard ({ commit, state }) {
            state.accounts.forEach( element => {
                if (element.pay_card == 1) {
                    let item = Object.assign({}, element);
                    commit('set_account_pay_card', item);
                }
            })
        },
        async getAccounts({commit, dispatch}, filters) {
            return await AccountsApi.get_all(filters)
                .then( response => {
                    commit('set_accounts', response.data);
                    dispatch('getAccountPayCard');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar cuentas bancarias, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, account) {
            return await AccountsApi.store(account)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de cuenta bancaria se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAccounts');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar cuenta bancaria, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, account) {
            return await AccountsApi.update(account)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización de cuenta bancaria se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getAccounts')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar cuenta bancaria, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, account) {
            let action = (account.active) ? 'inactivación' : 'activación';
            return await AccountsApi.change_status(account)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' de cuenta bancaria se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAccounts')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ' de cuenta bancaria, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, account) {
            return await AccountsApi.delete(account)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación de cuenta bancaria se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAccounts');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar cuenta bancaria, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getAccountsMovement({commit, dispatch}, account) {
            commit('set_movements', []);
            return await AccountsApi.get_movements(account)
                .then( response => {
                    commit('set_movements', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar movimientos de cuenta bancaria, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeMovement({dispatch, state}, movement) {
            return await AccountsApi.storeMovement(movement)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de movimiento se realizó de forma correcta.'
                    }, {root: true});
                    let newAccount = response.data;
                    dispatch('selectAccount', newAccount);
                    dispatch('getAccountsMovement', state.account);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar movimiento, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async deleteMovement({dispatch, state}, movement) {
            return await AccountsApi.deleteMovement(movement)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El movimiento se eliminó de forma correcta.'
                    }, {root: true});
                    let newAccount = response.data;
                    dispatch('selectAccount', newAccount);
                    dispatch('getAccountsMovement', state.account);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar movimiento, vuelva a intentarlo.'
                    }, {root: true});
                })
        },

    }
}