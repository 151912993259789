import Api from './Api'

const END_POINT = 'suppliers'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'suppliers-paginated'
                else
                    url = END_POINT

            }else{
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request)
    },
    async store(supplier) {
        return await Api.post(`${END_POINT}`, supplier)
    },
    async update(supplier) {
        return await Api.patch(`${END_POINT}/${supplier.id}`, supplier)
    },
    async change_status(supplier) {
        return await Api.patch(`${END_POINT}/${supplier.id}/change-status`, supplier)
    },
    async delete(supplier) {
        return await Api.delete(`${END_POINT}/${supplier.id}`, supplier)
    }
}