import Api from './Api';

const END_POINT = 'storehouses';

export default {
    get_all(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}`, request);
    },
}