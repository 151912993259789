import DebtsApi from '../../apis/Debts';

export default{
    namespaced: true,
    state: {
        debts: [],
        debt: null,
        response_payment: null,
        paginated:{}
    },
    mutations: {
        set_paginated(state,paginated){state.paginated=paginated},
        set_debts (state, data) {
            state.debts = data;
        },
        set_debt (state, data) {
            state.debt = data;
        },
        set_response_payment (state, data) {
            state.response_payment = data;
        }
    },
    actions: {
        selectDebt({commit}, data) {
            commit('set_debt', data);
        },
        async getDebts({commit, dispatch}, filters) {
            return await DebtsApi.get_all(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_debts', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_debts', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_debts', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_debts', dataPaginated)
                        commit('set_paginated', {})
                    }
                    // commit('set_debts', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar cuentas por cobrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeAmortize({commit, dispatch}, payment) {
            commit('set_response_payment', null);
            return await DebtsApi.amortize(payment)
                .then( response => {
                    commit('set_response_payment', response.data);
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pago se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getDebts');                      
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar pago, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}