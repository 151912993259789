import Api from './Api';

const END_POINT = 'debts';

export default {

    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'debts-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(`${url}`, request);
    },

    amortize(payment) {
        return Api.post(`${END_POINT}/amortize`, payment);
    },

}