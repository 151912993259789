import Api from './Api'

const END_POINT = 'plans'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(plan) {
        return await Api.post(`${END_POINT}`, plan)
    },
    async update(plan) {
        return await Api.patch(`${END_POINT}/${plan.id}`, plan)
    },
    async change_status(plan) {
        return await Api.patch(`${END_POINT}/${plan.id}/change-status`, plan)
    },
    async delete(plan) {
        return await Api.delete(`${END_POINT}/${plan.id}`, plan)
    }
}