import Api from './Api'

const END_POINT = 'accounts';

export default {
    get_all(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}`, request)
    },
    store(account) {
        return Api.post(`${END_POINT}`, account);
    },
    update(account) {
        return Api.patch(`${END_POINT}/${account.id}`, account);
    },
    change_status(account) {
        return Api.patch(`${END_POINT}/${account.id}/change-status`, account);
    },
    delete(account) {
        return Api.delete(`${END_POINT}/${account.id}`, account);
    },
    storeMovement(movement) {
        return Api.post(`${END_POINT}/movements`, movement);
    },
    deleteMovement(movement) {
        return Api.post(`${END_POINT}/movements/${movement.id}/delete`, movement);
    },
    get_movements(account) {
        return Api.get(`${END_POINT}/${account.id}/movements`);
    },
}