import UsersApi from '../../apis/Users';

export default {
    namespaced: true,
    state: {
        users: [],
        sellers: []
    },
    mutations: {
        set_users(state, data){
            state.users = data;
        },
        set_sellers (state, data) {
            state.sellers = data;
        }
    },
    actions: {
        run_set_users({commit}, data){
            commit('set_users', data);
        },
        async getStaff({commit}, filters) {
            return await UsersApi.getStaff(filters)
                .then( response => {
                    commit('set_users', response.data)
                })
                .catch( error => {
                    console.log(error)
                })
        },
        async getSellers({commit}, filters) {
            return await UsersApi.getSellers(filters)
                .then( response => {
                    commit('set_sellers', response.data);
                })
                .catch( error => {
                    console.log(error)
                })
        },
    }
}