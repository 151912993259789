import ReportsApi from '../../apis/Reports';
import {filter} from "core-js/internals/array-iteration";

export default {
    namespaced: true,
    state: {
        purchases: [],
        file_purchases: null,
        filters: null,
        purchase: null,
        detail: [],
        file_purchases_detail: null,
        file_name: null,
        paginated:{},
        filtersPaginated:{
            company_id: null,
            supplier_id: null,
            date_from: null,
            date_until:null,
            product_id: null,
            type_document: null,
            document: null
        }
    },
    mutations: {
        set_filter_paginated(state,paginated){
            state.filtersPaginated=paginated
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_purchases (state, data) {
            state.purchases = data;
        },
        set_file_purchases (state, value) {
            state.file_purchases = value;
        },
        set_filters (state, data) {
            state.filters = data;
        },
        set_purchase (state, data) {
            state.purchase = data;
        },
        set_detail (state, data) {
            state.detail = data;
        },
        set_file_purchases_detail (state, value) {
            state.file_purchases_detail = value;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        changeFilterPaginated({commit, dispatch}, filters) {
            // console.log(filters)
            commit('set_filter_paginated', filters)
        },
        setPurchases ({commit}, data) {
            commit('set_purchases', data);
        },
        emptyFilePurchases ({commit}) {
            commit('set_file_purchases', null);
        },
        emptyFilePurchaseDetail ({commit}) {
            commit('set_file_purchases_detail', null);
        },
        setFilters ({commit}, data) {
            commit('set_filters', data);
        },
        selectPurchase ({commit}, data) {
            commit('set_purchase', data);
        },
        async getPurchases({commit, dispatch}, filters) {
            commit('set_purchases', []);
            return await ReportsApi.getPurchases(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage!=-1){
                                        commit('set_purchases', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_purchases', dataPaginated)
                                        commit('set_paginated', {})
                                    }

                                } else {
                                    commit('set_purchases', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_purchases', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_purchases', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar compras, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getPurchasesDetail({commit, dispatch}, purchase) {
            commit('set_detail', []);
            return await ReportsApi.getPurchasesDetail(purchase)
                        .then( response => {
                            commit('set_detail', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar detalle de compra, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async purchasesExcel({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ReportsApi.purchasesExcel(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de compras, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async purchaseDetailExcel({commit, dispatch}, purchase) {
            commit('set_file_purchases_detail', null);
            return await ReportsApi.detailExcel(purchase)
                        .then( response => {
                            commit('set_file_purchases_detail', response.data.file_purchases_detail);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al descargar detalle de compra, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
    }
}