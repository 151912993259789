import Api from './Api'

const END_POINT = 'transfers'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'transfers-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(`${url}`, request)
    },

    async store(transfer) {
        return await Api.post(`${END_POINT}`, transfer)
    },

    async detail(transfer) {
        return await Api.get(`${END_POINT}/${transfer.id}/detail`)
    },

    async receive(transfer) {
        return await Api.post(`${END_POINT}/${transfer.id}/receive`, transfer.detail)
    },

    async cancel(transfer) {
        return await Api.post(`${END_POINT}/${transfer.id}/cancel`)
    },
}