import Api from './Api'

const END_POINT = 'capacities'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(capacity) {
        return await Api.post(`${END_POINT}`, capacity)
    },
    async update(capacity) {
        return await Api.patch(`${END_POINT}/${capacity.id}`, capacity)
    },
    async change_status(capacity) {
        return await Api.patch(`${END_POINT}/${capacity.id}/change-status`, capacity)
    },
    async delete(capacity) {
        return await Api.delete(`${END_POINT}/${capacity.id}`, capacity)
    }
}