import Api from './Api';

const END_POINT = 'reports';

export default {

    async getPurchases(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/purchases-paginated`
                else
                    url = `${END_POINT}/purchases`
            } else {
                url = `${END_POINT}/purchases`;
            }
        } else {
            url = `${END_POINT}/purchases`;
        }
        return await Api.get(`${url}`, request);
    },
    async getPurchasesDetail(purchase) {
        return await Api.get(`${END_POINT}/purchases/${purchase.id}`);
    },
    async purchasesExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/purchases-export`, request);
    },
    async detailExcel(purchase) {
        return await Api.get(`${END_POINT}/purchases-export/${purchase.id}`);
    },

    async getSales(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/sales-paginated`
                else
                    url = `${END_POINT}/sales`
            } else {
                url = `${END_POINT}/sales`;
            }
        } else {
            url = `${END_POINT}/sales`;
        }
        return await Api.get(`${url}`, request);
    },
    async getSalesDetail(sale) {
        return await Api.get(`${END_POINT}/sales/${sale.id}`);
    },
    async salesExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/sales-export`, request);
    },
    async saleDetailExcel(sale) {
        return await Api.get(`${END_POINT}/sales-export/${sale.id}`);
    },
    async saleAccountingExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/sales-accounting`, request);
    },

    async getTransfers(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/transfers-paginated`
                else
                    url = `${END_POINT}/transfers`
            } else {
                url = `${END_POINT}/transfers`;
            }
        } else {
            url = `${END_POINT}/transfers`;
        }
        return await Api.get(`${url}`, request);
    },
    async getTransferDetail(transfer) {
        return await Api.get(`${END_POINT}/transfers/${transfer.id}`);
    },
    async transferExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/transfers-export`, request);
    },
    async transferDetailExcel(transfer) {
        return await Api.get(`${END_POINT}/transfers-export/${transfer.id}`);
    },

    async getTillsOperations(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/tills-paginated`
                else
                    url = `${END_POINT}/tills`
            } else {
                url = `${END_POINT}/tills`;
            }
        } else {
            url = `${END_POINT}/tills`;
        }
        return await Api.get(`${url}`, request);
    },
    async getTillDetail(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/tills/detail`, request);
    },
    async tillsExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/tills-export`, request);
    },
    async tillsDetailExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/tills-export/detail`, request);
    },

    async getSalesVoucher(sale_id) {
        return await Api.get(`${END_POINT}/sales-voucher/${sale_id}`);
    },

    async getKardexMovements(filters = null) {
        let request = {
            params: filters
        }
        let urlPaginator = ''
        console.log('esta es las respuesta en  al api');
        console.log(filters);
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                urlPaginator = `${END_POINT}/paginator-get-kardex-movements`;
                else
                urlPaginator =  `${END_POINT}/get-kardex-movements`
            } else {
                urlPaginator = `${END_POINT}/get-kardex-movements`;
            }
        } else {
            urlPaginator = `${END_POINT}/get-kardex-movements`;
        }
        return await Api.get(`${urlPaginator}`, request);
        //return await Api.get(`${END_POINT}/get-kardex-movements`, request);
    },
    async downloadConsumption (params) {
        let request = {
            params: params
        }
        return Api.get(`${END_POINT}/download-consumption`, request);
    },
    async downloadDecrease (params) {
        let request = {
            params: params
        }
        return Api.get(`${END_POINT}/download-decrease`, request);
    },
    async getSoldProducts(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/sold-products-paginated`
                else
                    url =  `${END_POINT}/sold-products`
            } else {
                url =  `${END_POINT}/sold-products`;
            }
        } else {
            url =  `${END_POINT}/sold-products`;
        }
        return await Api.get(`${url}`, request);
    },
    async downloadSoldProducts (params) {
        let request = {
            params: params
        }
        return Api.get(`${END_POINT}/download-sold-products`, request);
    },
    async getAttendedServices(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/attended-services`, request);
    },
    async downloadAttendedServices (params) {
        let request = {
            params: params
        }
        return Api.get(`${END_POINT}/download-attended-services`, request);
    },
    async getAttendedServicesDetail(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/attended-services-detail`, request);
    },
}