import ProductsApi from '../../apis/Products'

export default {
    namespaced: true,
    state: {
        products: [],
        product: null,
        selected_product: null,
        prices: [],
        product_units: [],
        product_prices: [],
        file_name: null,
        paginated: {},
        filtersPaginated:{
            name: null,
            sku: null,
            barcode: null,
            category_id: null,
            brand_id: null,
            unit_id: null,
            office_id: null
        }
    },
    mutations: {
        set_filter_paginated(state,filtersPaginated){
            state.filtersPaginated=filtersPaginated
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_products(state, products){
            state.products = products;
        },
        set_product(state, product){
            state.product = product;
        },
        set_selected_product(state, selected_product){
            state.selected_product = selected_product;
        },
        set_prices(state, data) {
            state.prices = data;
        },
        set_product_units (state, data) {
            state.product_units = data;
        },
        add_product_unit (state, data) {
            state.product_units.push(data);
        },
        remove_product_unit (state, index) {
            state.product_units.splice(index, 1);
        },
        set_product_prices (state, data) {
            state.product_prices = data;
        },
        add_product_price (state, data) {
            state.product_prices.push(data);
        },
        remove_product_price (state, index) {
            state.product_prices.splice(index, 1);
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            // console.log(filters)
            commit('set_filter_paginated',filters)
        },
        emptyProducts({commit}) {
            commit('set_products', []);
        },
        emptyProduct({commit}) {
            commit('set_product', null);
        },
        selectProduct({commit}, product) {
            commit('set_selected_product', product);
        },
        runCleanProducts({commit}){
            commit('set_products', [])
        },
        async getProducts({commit, dispatch}, filters) {
            console.log(filters);
            return await ProductsApi.get_all(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_products', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_products', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_products', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_products', dataPaginated)
                        commit('set_paginated', {})
                    }
                    // commit('set_products', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, product) {
            return await ProductsApi.store(product)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    let filters = {
                        category_id: response.data.category_id
                    }
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async show({commit, dispatch}, product) {
            return await ProductsApi.show(product)
                .then( response => {                                
                    commit('set_product', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al obtener datos de producto, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, product) {
            return await ProductsApi.update(product)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});                                
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, product) {
            let action = (product.active) ? 'inactivación' : 'activación';
            return await ProductsApi.change_status(product)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    let filters = {
                        office_id: response.data.office_id
                    }
                    dispatch('getProducts', filters)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, product) {
            return await ProductsApi.delete(product)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    let filters = {
                        office_id: response.data.office_id
                    }
                    dispatch('getProducts', filters)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        empty_prices({commit}) {
            commit('set_prices', []);
        },
        async getPrices({commit, dispatch}, product) {
            return await ProductsApi.get_prices(product)
                .then( response => {
                    commit('set_prices', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar precios, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        setProductUnits ({commit}, data) {
            commit('set_product_units', data);
        },
        addProductUnit ({commit}, data) {
            commit('add_product_unit', data);
        },
        removeProductUnit ({commit}, index) {
            commit('remove_product_unit', index);
        },
        setProductPrices ({commit}, data) {
            commit('set_product_prices', data);
        },
        addProductPrice ({commit}, data) {
            commit('add_product_price', data);
        },
        removeProductPrice ({commit}, index) {
            commit('remove_product_price', index);
        },
        async downloadProducts({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ProductsApi.download(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async upload({dispatch}, product) {
            return await ProductsApi.upload(product)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'Los productos del archivo se registraron de forma correcta.'
                    }, {root: true});
                    dispatch('getProducts');
                })
                .catch( error => {
                    let message = 'Ocurrió un error al registrar productos del archivo, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.message) {
                        message = error.response.data.message;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
    }
}