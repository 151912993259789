import Api from './Api';

const END_POINT = 'users';

export default {
    async lock(user) {
        return await Api.patch(`${END_POINT}/${user.id}/lock`, user);
    },

    async getStaff(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/staff`, request);
    },

    async getSellers(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/sellers`, request);
    },

    async getListUsers(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/list-users`, request);
    },
}