import Api from './Api'

const END_POINT = 'purchases'

export default {
    async store(purchase) {
        return await Api.post(`${END_POINT}`, purchase)
    },
    async update(purchase) {
        return await Api.patch(`${END_POINT}/${purchase.id}`, purchase)
    },
    async delete(purchase) {
        return await Api.delete(`${END_POINT}/${purchase.id}`);
    }
}