import ColorsApi from '../../apis/Colors'

export default {
    namespaced: true,
    state: {
        colors: []
    },
    mutations: {
        set_colors(state, colors) {
            state.colors = colors
        }
    },
    actions: {
        async getColors({commit, dispatch}, filters) {
            return await ColorsApi.get_all(filters)
                        .then( response => {
                            commit('set_colors', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, color) {
            return await ColorsApi.store(color)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getColors')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, color) {
            return await ColorsApi.update(color)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getColors')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, color) {
            let action = (color.active) ? 'inactivación' : 'activación';
            return await ColorsApi.change_status(color)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getColors')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, color) {
            return await ColorsApi.delete(color)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getColors')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        }
    }
}