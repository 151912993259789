import Api from './Api';

const END_POINT = 'credit-notes';

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request);
    },
    async downloadExcel(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/download`, request);
    },
}