import CategoriesApi from '../../apis/Categories'

export default {
    namespaced: true,
    state: {
        categories: [],
        paginated: {},
        filterPaginated:{name:null,description:null}
    },
    mutations: {
        set_categories(state, categories) {
            state.categories = categories
        },
        set_paginated(state, paginated) {
            state.paginated = paginated
        },
        set_filter_paginated(state,data){
            state.filterPaginated=data;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            commit('set_filter_paginated',filters)
        },
        async getCategories({commit, dispatch}, filters) {
            console.log(filters)
            return await CategoriesApi.get_all(filters)
                .then(response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_categories', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_categories', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_categories', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_categories', dataPaginated)
                        commit('set_paginated', {})
                    }


                })
                .catch(error => {
                    console.log(error)
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, category) {
            return await CategoriesApi.store(category)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getCategories')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, category) {
            return await CategoriesApi.update(category)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getCategories')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, category) {
            let action = (category.active) ? 'inactivación' : 'activación';
            return await CategoriesApi.change_status(category)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getCategories')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, category) {
            return await CategoriesApi.delete(category)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getCategories')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}