import PurchasesApi from '../../apis/Purchases'

import ArrayTools from '../../helpers/ArrayTools'

export default {
    namespaced: true,
    state: {
        purchase: null,        
        supplier_id: null,
        register_date: null,
        sale_order: null,
        type_document: null,
        serie: null,
        correlative: null,
        credit_purchase: false,
        date_payment: null,
        purchase_detail: [],
        subtotal: null,
        discount: null,
        igv: null,
        total: null,
        type_operation: 'create',
        purchase_id: null,
        storehouse_id: null
    },
    mutations: {
        set_supplier_id(state, supplier_id) {
            state.supplier_id = supplier_id
        },
        set_register_date(state, register_date) {
            state.register_date = register_date
        },
        set_sale_order(state, sale_order) {
            state.sale_order = sale_order
        },
        set_type_document (state, value) {
            state.type_document = value;
        },
        set_serie(state, serie) {
            state.serie = serie
        },
        set_correlative(state, correlative) {
            state.correlative = correlative
        },
        set_purchase(state, purchase) {
            state.purchase = purchase
        },
        set_credit_purchase(state, value) {
            state.credit_purchase = value;
        },
        set_date_payment (state, value) {
            state.date_payment = value;
        },
        set_purchase_detail (state, data) {
            state.purchase_detail = data;
        },
        add_item_purchase_detail (state, item) {
            state.purchase_detail.push(item);
        },
        remove_item_purchase_detail (state, index) {
            state.purchase_detail.splice(index, 1);
        },
        replace_item_purchase_detail (state, data) {
            state.purchase_detail.splice(data.index, 1, data.item);
        },
        set_subtotal (state, value) {
            state.subtotal = value;
        },
        set_discount (state, value) {
            state.discount = value;
        },
        set_igv (state, value) {
            state.igv = value;            
        },
        set_total (state, value) {
            state.total = value;
        },
        set_type_operation (state, value) {
            state.type_operation = value;
        },
        set_purchase_id (state, value) {
            state.purchase_id = value;
        },
        set_storehouse_id (state, value) {
            state.storehouse_id = value;
        }
    },
    actions: {
        set_type_operation ({commit}, value) {
            commit('set_type_operation', value);
        },
        setPurchaseDetail ({commit, dispatch}, data) {
            commit('set_purchase_detail', data);
            dispatch('calculateTotaAmounts');
        },
        addItemPurchaseDetail ({commit, dispatch}, item) {
            commit('add_item_purchase_detail', item);
            dispatch('calculateTotaAmounts');
        },
        removeItemPurchaseDetail ({commit, dispatch}, index) {
            commit('remove_item_purchase_detail', index);
            dispatch('calculateTotaAmounts');
        },
        replaceItemPurchaseDetail ({commit, dispatch}, data) {
            commit('replace_item_purchase_detail', data);
            dispatch('calculateTotaAmounts');
        },
        clean_data({commit}) {
            commit('set_purchase_id', null)
            commit('set_supplier_id', null)
            commit('set_register_date', null)
            commit('set_sale_order', null)
            commit('set_credit_purchase', false);
            commit('set_date_payment', null);
            commit('set_type_document', null);
            commit('set_serie', null);
            commit('set_correlative', null);
            commit('set_storehouse_id', null);
        },
        calculateTotaAmounts({state, commit}){
            let quantity = 0
            let discount = 0
            let subtotal = 0
            let igv = 0
            let total = 0
            let data = state.purchase_detail
            if (data) {
                data.forEach(element => {
                    quantity = quantity + parseFloat(element.quantity);
                    discount = discount + parseFloat(element.discount);
                    subtotal = subtotal + parseFloat(element.total);
                    igv =  igv + parseFloat(element.igv);
                });
            }            
            total = subtotal + igv;
            
            quantity = Math.round((quantity) * 100) / 100;
            discount = Math.round((discount) * 100) / 100;
            subtotal = Math.round((subtotal) * 100) / 100;
            igv = Math.round((igv) * 100) / 100;
            total = Math.round((total) * 100) / 100;

            commit('set_subtotal', subtotal);
            commit('set_discount', discount);
            commit('set_igv', igv);
            commit('set_total', total);
        },
        async storePurchase({state, dispatch}) {
            let purchase = {
                supplier_id: state.supplier_id,
                register_date: state.register_date,
                sale_order: state.sale_order,
                type_document: state.type_document,
                serie: state.serie,
                correlative: state.correlative,
                discount: state.discount,
                subtotal: state.subtotal,
                igv: state.igv,
                total: state.total,
                credit_purchase: state.credit_purchase,
                date_payment: state.date_payment,
                detail: state.purchase_detail,
                storehouse_id: state.storehouse_id
            }

            console.log(purchase);
            
            return await PurchasesApi.store(purchase)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de compra se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('clean_data')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar compra, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, purchase) {
            return await PurchasesApi.delete(purchase)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La compra fue eliminada'
                    }, {root: true});
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        selectPurchaseToEdit ({commit, dispatch}, purchase) {
            commit('set_purchase_id', purchase.id);
            commit('set_supplier_id', purchase.supplier_id)
            commit('set_register_date', purchase.register_date)
            commit('set_sale_order', null)
            commit('set_credit_purchase', purchase.credit_purchase);
            commit('set_date_payment', purchase.date_payment);
            commit('set_type_document', purchase.type_document);
            commit('set_serie', purchase.serie);
            commit('set_correlative', purchase.correlative);
            commit('set_purchase_detail', purchase.detail);
            commit('set_subtotal', parseFloat(purchase.subtotal));
            commit('set_discount', parseFloat(purchase.discount));
            commit('set_igv', parseFloat(purchase.igv));
            commit('set_total', parseFloat(purchase.total));
            commit('set_storehouse_id', purchase.storehouse_id);
        },
        async updatePurchase({state, dispatch}) {
            let purchase = {
                id: state.purchase_id,
                supplier_id: state.supplier_id,
                register_date: state.register_date,
                sale_order: state.sale_order,
                type_document: state.type_document,
                serie: state.serie,
                correlative: state.correlative,
                discount: state.discount,
                subtotal: state.subtotal,
                igv: state.igv,
                total: state.total,
                credit_purchase: state.credit_purchase,
                date_payment: state.date_payment,
                detail: state.purchase_detail,
                storehouse_id: state.storehouse_id
            }
            
            return await PurchasesApi.update(purchase)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La compra fue actualizada de forma correcta.'
                    }, {root: true});
                    dispatch('clean_data')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar compra, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        
    }
}