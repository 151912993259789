import Api from './Api';

const END_POINT = 'roles';

export default{
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request);
    },
    async store(role) {
        return await Api.post(`${END_POINT}`, role);
    },
    async update(role) {
        return await Api.patch(`${END_POINT}/${role.id}`, role);
    },
    async change_status(role) {
        return await Api.patch(`${END_POINT}/${role.id}/change-status`, role);
    },
    async delete(role) {
        return await Api.delete(`${END_POINT}/${role.id}`, role);
    }
}