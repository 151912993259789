import Api from './Api';

const END_POINT = 'sales';

export default {

    async store(sale) {
        return await Api.post(`${END_POINT}`, sale);
    },

    get_orders(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/orders`, request)
    },

    amortize(payment) {
        return Api.post(`${END_POINT}/amortize`, payment);
    },

    async storeCreditNote(creditNote) {
        return await Api.post(`${END_POINT}/cancel`, creditNote);
    },

    get_by_station(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/get-by-station`, request)
    },

    async storeByStation(sale) {
        return await Api.post(`${END_POINT}/store-by-station`, sale);
    },
    async updateByStation(sale) {
        return await Api.post(`${END_POINT}/update-by-station`, sale);
    },
    async storePaymentByStation(sale) {
        return await Api.post(`${END_POINT}/store-payment-by-station`, sale);
    },

}