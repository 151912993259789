import axios from 'axios'

let Api = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

Api.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('access_token')

        if (token) {
            config.headers['Authorization'] = token
        }

        return config
    },
  
    (error) => {
        return Promise.reject(error)
    }
  )

export default Api;