import ReportsApi from '../../apis/Reports';

export default {
    namespaced: true,
    state: {
        sales: [],
        filters: null,
        sale: null,
        detail: null,
        file_sales: null,
        voucher: null,
        paginated:{},
        filtersPaginated:{
            company_id: null,
            sede_id: null,
            office_id: null,
            type_document: null,
            modality: null,
            date_from:null,
            date_until: null,
            customer_id: null,
            product_id: null
        }
    },
    mutations: {
        set_filter_paginated(state,paginated){
            state.filtersPaginated=paginated
        },
        set_paginated (state, data) {
            state.paginated = data;
        },
        set_sales (state, data) {
            state.sales = data;
        },
        set_filters (state, data) {
            state.filters = data;
        },
        set_sale (state, data) {
            state.sale = data;
        },
        set_detail (state, data) {
            state.detail = data;
        },
        set_file_sales (state, value) {
            state.file_sales = value;
        },
        set_voucher (state, data) {
            state.voucher =  data
        }
    },
    actions: {
        changeFilterPaginated({commit, dispatch}, filters) {
            // console.log(filters)
            commit('set_filter_paginated', filters)
        },
        setSales ({commit}, data) {
            commit('set_sales', []);
        },
        setFilters ({commit}, data) {
            commit('set_filters', data);
        },
        selectSale ({commit}, data) {
            commit('set_sale', data);
        },
        emptyFileSales ({commit}, value) {
            commit('set_file_sales', value);
        },
        async getSales({commit, dispatch}, filters) {
            commit('set_sales', []);
            return await ReportsApi.getSales(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage!=-1){
                                        commit('set_sales', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_sales', dataPaginated)
                                        commit('set_paginated', {})
                                    }

                                } else {
                                    commit('set_sales', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_sales', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_sales', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar ventas, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getSaleDetail({commit, dispatch}, sale) {
            commit('set_detail', []);
            return await ReportsApi.getSalesDetail(sale)
                        .then( response => {
                            commit('set_detail', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar detalle de venta, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async salesExcel({commit, dispatch}, filters) {
            commit('set_file_sales', null);
            return await ReportsApi.salesExcel(filters)
                        .then( response => {
                            commit('set_file_sales', response.data.file_sales);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al descargar reporte de ventas, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async saleDetailExcel({commit, dispatch}, sale) {
            commit('set_file_sales', null);
            return await ReportsApi.saleDetailExcel(sale)
                        .then( response => {
                            commit('set_file_sales', response.data.file_excel);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al descargar detalle de venta, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getSaleVoucher({commit, dispatch}, sale_id) {
            commit('set_voucher', null);
            return await ReportsApi.getSalesVoucher(sale_id)
                .then( response => {
                    commit('set_voucher', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al imprimir voucher, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async saleAccountingExcel({commit, dispatch}, filters) {
            commit('set_file_sales', null);
            return await ReportsApi.saleAccountingExcel(filters)
                .then( response => {
                    commit('set_file_sales', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de ventas, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}