import Api from './Api'

const END_POINT = 'employees'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'employees-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(`${url}`, request);
    },
    async store(employee) {
        return await Api.post(`${END_POINT}`, employee)
    },
    async update(employee) {
        return await Api.patch(`${END_POINT}/${employee.id}`, employee)
    },
    async change_status(employee) {
        return await Api.patch(`${END_POINT}/${employee.id}/change-status`, employee)
    },
    async delete(employee) {
        return await Api.delete(`${END_POINT}/${employee.id}`, employee)
    },
    async get_worker(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/get-workers`, request)
    },
}