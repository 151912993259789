import TypesDocumentsApi from '../../apis/TypesDocuments'

export default {
    namespaced: true,
    state: {
        types_documents: []
    },
    mutations: {
        set_types_documents(state, types_documents){
            state.types_documents = types_documents
        }
    },
    actions: {
        async getTypesDocuments({commit}, filters) {
            return await TypesDocumentsApi.get_all(filters)
                        .then( response => {
                            commit('set_types_documents', response.data)
                        })
                        .catch( error => {
                            console.log(error)
                        })
        }
    }
}