import Api from './Api'

const END_POINT = 'colors'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(color) {
        return await Api.post(`${END_POINT}`, color)
    },
    async update(color) {
        return await Api.patch(`${END_POINT}/${color.id}`, color)
    },
    async change_status(color) {
        return await Api.patch(`${END_POINT}/${color.id}/change-status`, color)
    },
    async delete(color) {
        return await Api.delete(`${END_POINT}/${color.id}`, color)
    }
}