import SaleOptionsApi from '../../apis/SaleOptions'

export default {
    namespaced: true,
    state: {
        sale_options: []
    },
    mutations: {
        set_sale_options(state, data){
            state.sale_options = data
        }
    },
    actions: {
        async getSalesOptions({commit}, filters) {
            return await SaleOptionsApi.get_all(filters)
                        .then( response => {
                            commit('set_sale_options', response.data)
                        })
                        .catch( error => {
                            console.log(error)
                        })
        },
    }
}