import DashboardApi from '../../apis/Dashboard';

export default {
    namespaced: true,
    state: {
        productosVentasDiariasHoy : [],
        productosVentasAyer: [],
        productosGanancias: [],
        cantidadProductosMes: [],
        cantidadProductosMesPasado:[],
        totalCantidad:[],
        reportesProductosDiarios:[],
        ventasHoy: [],
        ventasAyer: [],
        totalVentas: [],
        ventasMes: [],
        ventasMesPasado: [],
        totalVentasMes: [],
        rendimientoVentas: [],
        serviciosDiarios: [],
        serviciosDiariosAyer: [],
        totalServicios: [],
        serviciosMesActual: [],
        serviciosMesPasado: [],
        totalServiciosMes: [],
        reporteServicios: [],
        compraMes: [],
        compraMesPasado: [],
        compraAnio:[],
        reporteCompras: [],
    },
    mutations: {
        set_productosVentasDiariasHoy (state, data) {
            state.productosVentasDiariasHoy = data
        },
        set_productosVentasAyer (state, data) {
            state.productosVentasAyer = data
        },
        set_productosGanancias (state, data) {
            state.productosGanancias = data
        },
        set_cantidadProductosMes (state, data) {
            state.cantidadProductosMes = data
        },
        set_cantidadProductosMesPasado(state, data) {
            state.cantidadProductosMesPasado = data
        },
        set_totalCantidad(state, data) {
            state.totalCantidad = data
        },
        set_reportesProductosDiarios (state, data){
            state.reportesProductosDiarios = data
        },
        set_ventasHoy(state, data){
            state.ventasHoy = data
        },
        set_ventasAyer(state, data){
            state.ventasAyer = data
        },
        set_ventasMes( state, data){
            state.ventasMes = data
        },
        set_ventasMesPasado(state, data){
            state.ventasMesPasado = data
        },
        set_totalVentas(state, data){
            state.totalVentas = data
        },
        set_totalVentasMes(state, data){
            state.totalVentasMes = data
        },
        set_rendimientoVentas(state, data) {
            state.rendimientoVentas = data
        },
        set_serviciosDiarios(state, data) {
            state.serviciosDiarios = data
        },
        set_serviciosDiariosAyer(state, data) {
            state.serviciosDiariosAyer = data
        },
        set_totalServicios(state, data) {
            state.totalServicios = data
        },
        set_serviciosMesActual(state, data) {
            state.serviciosMesActual = data
        },
        set_serviciosMesPasado(state, data) {
            state.serviciosMesPasado = data
        },
        set_totalServiciosMes(state, data) {
            state.totalServiciosMes = data
        },
        set_reporteServicios(state, data) {
            state.reporteServicios = data
        },
        set_compraMes(state, data) {
            state.compraMes = data
        },
        set_compraMesPasado(state, data) {
            state.compraMesPasado = data
        },
        set_compraAnio(state, data) {
            state.compraAnio = data
        },
        set_reporteCompras( state, data) {
            state.reporteCompras = data
        }
    },
    actions: {
        async getProductosVentasHoy({commit, dispatch}) {
            return await DashboardApi.ventasProductoDiarioHoy()
                            .then( response => {
                                console.log(response.data);
                                commit('set_productosVentasDiariasHoy', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data.'
                                }, {root: true});
                            })
        },
        async getProductosVentasAyer({commit, dispatch}) {
            return await DashboardApi.ventasProductoDiarioAyer()
                            .then( response => {
                                commit('set_productosVentasAyer', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data.'
                                }, {root: true});
                            })
        },
        async getProductosGanancias({commit, dispatch}){
            return await DashboardApi.productosGanancias()
                            .then( response => {
                                commit('set_productosGanancias', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data.'
                                }, {root: true})
                            })
        },
        async getCantidadProductosMes({commit, dispatch}){
            return await DashboardApi.cantidadProductosMes()
                            .then( response => {
                                commit('set_cantidadProductosMes', response.data)
                            })
                            .catch(error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data.'
                                }, {root: true})
                            })
        },
        async getCantidadProductosMesPasado({commit, dispatch}){
            return await DashboardApi.cantidadProductosMesPasado()
                            .then( response => {
                                commit('set_cantidadProductosMesPasado', response.data)
                            })
                            .catch(error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data.'
                                }, {root: true})
                            })
        },
        async getTotalCantidad({commit, dispatch}) {
            return await DashboardApi.totalCantidad()
                            .then( response => {
                                commit('set_totalCantidad', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                },{root: true})
                            })
        },
        async getReporteProductosDiarios({commit, dispatch}, mes) {
            return await DashboardApi.reporteProductosDiarios(mes)
                            .then( response => {
                                commit('set_reportesProductosDiarios', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getVentasHoy({commit, dispatch}){
            return await DashboardApi.ventasHoy()
                            .then( response => {
                                commit('set_ventasHoy', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getVentasAyer({commit, dispatch}){
            return await DashboardApi.ventasAyer()
                            .then( response => {
                                commit('set_ventasAyer', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getTotalVentas({commit, dispatch}) {
            return await DashboardApi.totalesVentasDiarias()
                            .then( response => {
                                commit('set_totalVentas', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getVentasMes({commit, dispatch}) {
            return await DashboardApi.ventasMes()
                            .then( response => {
                                commit('set_ventasMes', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getVentasMesPasado({commit, dispatch}){
            return await DashboardApi.ventasMesPasado()
                            .then( response => {
                                commit('set_ventasMesPasado', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async gettotalVentasMes({commit, dispatch}){
            return await DashboardApi.totalVentasMes()
                            .then( response => {
                                commit('set_totalVentasMes', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getComprasHoy({commit, dispatch}){
            return await DashboardApi.comprasHoy()
                            .then( response => {
                                commit('set_comprasHoy', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getComprasAyer({commit, dispatch}){
            return await DashboardApi.comprasAyer()
                            .then( response => {
                                commit('set_comprasAyer', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getRendimientoVantas({commit, dispatch}) {
            return await DashboardApi.rendimientoVentas()
                            .then( response => {
                                commit('set_rendimientoVentas', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getServiciosDiarios({commit,dispatch}) {
            return await DashboardApi.serviciosHoy()
                            .then( response => {
                                commit('set_serviciosDiarios', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getServiciosDiariosAyer({commit,dispatch}) {
            return await DashboardApi.serviciosAyer()
                            .then( response => {
                                commit('set_serviciosDiariosAyer', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getTotalServicios({commit, dispatch}) {
            return await DashboardApi.totalServicios()
                            .then( response => {
                                commit('set_totalServicios', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getServiciosMes({commit, dispatch}) {
            return await DashboardApi.serviciosMesActual()
                            .then( response => {
                                commit('set_serviciosMesActual', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getServiciosMesPasado({commit, dispatch}) {
            return await DashboardApi.serviciosMesPasado()
                            .then( response => {
                                commit('set_serviciosMesPasado', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getTotalServiciosMes({commit, dispatch}) {
            return await DashboardApi.totalServiciosMes()
                            .then( response => {
                                commit('set_totalServiciosMes', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getReporteServicios({commit, dispatch}, mes) {
            return await DashboardApi.reporeServicios(mes)
                            .then( response => {
                                commit('set_reporteServicios', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getCompraMes({commit, dispatch}) {
            return await DashboardApi.comprasMes()
                            .then( response => {
                                commit('set_compraMes', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getCompraMesPasado({commit, dispatch}) {
            return await DashboardApi.compraMesPasado()
                            .then( response => {
                                commit('set_compraMesPasado', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getCompraAnio({commit, dispatch}) {
            return await DashboardApi.compraAnio()
                            .then( response => {
                                commit('set_compraAnio', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        },
        async getReporteCompras({commit, dispatch}) {
            return await DashboardApi.reporteCompras()
                            .then( response => {
                                commit('set_reporteCompras', response.data);
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al obtener la data'
                                })
                            })
        }
    }
}