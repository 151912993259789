import ImeisApi from '../../apis/Imeis';

export default {
    namespaced: true,
    state: {
        imei: null
    },
    mutations: {
        set_imei (state, data) {
            state.imei = data;
        }
    },
    actions: {
        async searchImei({commit, dispatch}, imei_number) {
            commit('set_imei', null);
            return await ImeisApi.searchImei(imei_number)
                .then( response => {
                    commit('set_imei', response.data)
                })
                .catch( error => {
                    let msg = 'Ocurrió un error al buscar imeis, vuelva a intentarlo.';
                    if (error.response.data.message) {
                        msg = error.response.data.message;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: msg
                    }, {root: true});
                })
        }
    }
}