import { type } from "jquery"

export default {
    getIndexById(data, search, type_item = null) {
        let indexFounded = -1
        data.forEach( (element, index) => {
            if (type_item) {
                if (element.id === search && element.type_item == type_item) {
                    indexFounded = index
                }
            } else {
                if (element.id === search) {
                    indexFounded = index
                }
            }            
        })

        return indexFounded
    },

    getIndexByProductIdInOrder(data, search) {
        let indexFounded = -1
        if (data) {
            data.forEach( (element, index) => {
                if (element.product_id === search) {
                    indexFounded = index
                }
            })
        }        

        return indexFounded
    },

    getSedesByCompanyId(data_sedes, company_id) {
        let sedes = []
        data_sedes.forEach( (element) => {
            if (element.company_id == company_id ) {
                sedes.push(element)
            }
        } )

        return sedes
    },

    getOfficesBySedeId(data_offices, sede_id) {
        let offices = []
        data_offices.forEach( (element) => {
            if (element.sede_id == sede_id ) {
                offices.push(element)
            }
        } )

        return offices
    },

    getOfficesByCompanyId(data_offices, company_id) {
        let offices = []
        data_offices.forEach( (element) => {
            if (element.company_id == company_id ) {
                offices.push(element)
            }
        } )

        return offices
    },

    getTypeDocumentByID(data, type_id) {
        let type = null
        data.forEach( (element) => {
            if (element.id == type_id ) {
                type = element
            }
        } )

        return type
    },

    getElementById(data, id_search) {
        let element_founded = null
        data.forEach( (element) => {
            if (element.id == id_search ) {
                element_founded = element
            }
        } )

        return element_founded
    },

    getTypesByBrandId(data_types, brand_id) {
        let types = []
        data_types.forEach( (element) => {
            if (element.brand_id == brand_id ) {
                types.push(element)
            }
        } )

        return types
    },

    getElementByImei(data, imei_search) {
        let element_founded = null
        data.forEach( (element) => {
            if (element.imei_1 == imei_search ) {
                element_founded = element
            }
        } )

        return element_founded
    },

    existMenuInPermission(data, menu) {
        let exist = false;
        data.forEach( (element) => {
            if (element.includes(menu) ) {
                exist = true;
                return exist;
            }
        } );
        return exist;
    },

    existProductInCart (cart, product_id, type_item = null) {
        let product_data = null;
        
        cart.forEach( element => {
            if (element.product_id == product_id) {
                if (type_item) {
                    if (element.type_item == type_item) {
                        product_data = element;
                    }
                } else {
                    product_data = element;
                }                
            }
        });

        return product_data;
    }

}