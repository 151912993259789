import Api from './Api'

const END_POINT = 'types'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(type) {
        return await Api.post(`${END_POINT}`, type)
    },
    async update(type) {
        return await Api.patch(`${END_POINT}/${type.id}`, type)
    },
    async change_status(type) {
        return await Api.patch(`${END_POINT}/${type.id}/change-status`, type)
    },
    async delete(type) {
        return await Api.delete(`${END_POINT}/${type.id}`, type)
    }
}