import BrandsApi from '../../apis/Brands'

export default {
    namespaced: true,
    state: {
        brands: [],
        paginated: {},
        filterPaginated:{name:null,description:null}
    },
    mutations: {
        set_brands(state, brands) {
            state.brands = brands
        },
        set_paginated(state, paginated) {
            state.paginated = paginated
        },
        set_filter_paginated(state,data){
            state.filterPaginated=data;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            commit('set_filter_paginated',filters)
        },
        async getBrands({commit, dispatch}, filters) {
            return await BrandsApi.get_all(filters)
                .then(response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage != -1) {
                                commit('set_brands', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            } else {
                                commit('set_brands', dataPaginated)
                                commit('set_paginated', {})
                            }
                        } else {
                            commit('set_brands', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_brands', dataPaginated)
                        commit('set_paginated', {})
                    }
                    // commit('set_brands', response.data)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, brand) {
            return await BrandsApi.store(brand)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getBrands')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, brand) {
            return await BrandsApi.update(brand)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getBrands')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, brand) {
            let action = (brand.active) ? 'inactivación' : 'activación';
            return await BrandsApi.change_status(brand)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getBrands')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, brand) {
            return await BrandsApi.delete(brand)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getBrands')
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}