import SedesApi from '../../apis/Sedes'

export default {
    namespaced: true,
    state: {
        sedes: []
    },
    mutations: {
        set_sedes(state, sedes) {
            state.sedes = sedes
        }
    },
    actions: {
        async getSedes({commit, dispatch}, filters) {
            return await SedesApi.get_all(filters)
                        .then( response => {
                            commit('set_sedes', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, sede) {
            return await SedesApi.store(sede)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSedes');                                
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, sede) {
            return await SedesApi.update(sede)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getSedes');                                
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, sede) {
            let action = (sede.active) ? 'inactivación' : 'activación';
            return await SedesApi.change_status(sede)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSedes');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, sede) {
            return await SedesApi.delete(sede)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSedes');                                
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        }
    }
}