import CreditNotesApi from '../../apis/CreditNotes';

export default {
    namespaced: true,
    state: {
        credit_notes: [],
        filters: null,
        file_name: null
    },
    mutations: {
        set_credit_notes (state, data) {
            state.credit_notes = data;
        },
        set_filters (state, data) {
            state.filters = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        setFilters ({commit}, data) {
            commit('set_filters', data);
        },
        async getCreditNotes({commit, dispatch}, filters) {
            return await CreditNotesApi.get_all(filters)
                .then( response => {
                    commit('set_credit_notes', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar notas de crédito, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadExcel({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await CreditNotesApi.downloadExcel(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de notas de crédito, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}