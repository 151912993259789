import ReportsApi from '../../apis/Reports';

export default {
    namespaced: true,
    state: {
        transfers: [],
        filters: null,
        transfer: null,
        detail: [],
        file_excel: null,
        paginated:{}
    },
    mutations: {
        set_paginated(state,data){state.paginated=data},
        set_transfers (state, data) {
            state.transfers = data
        },
        set_filters (state, data) {
            state.filters = data;
        },
        set_transfer (state, data) {
            state.transfer = data;
        },
        set_detail (state, data) {
            state.detail = data;
        },
        set_file_excel (state, value) {
            state.file_excel = value;
        }
    },
    actions: {
        setTransfers ({commit}, data) {
            commit('set_transfers', data);
        },
        setFilters ({commit}, data) {
            commit('set_filters', data);
        },
        selectTransfer ({commit}, data) {
            commit('set_transfer', data);
        },
        async getTransfers({commit, dispatch}, filters) {
            commit('set_transfers', []);
            return await ReportsApi.getTransfers(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage!=-1){
                                        commit('set_transfers', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_transfers', dataPaginated)
                                        commit('set_paginated', {})
                                    }

                                } else {
                                    commit('set_transfers', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_transfers', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_transfers', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar transferecias, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getTransferDetail({commit, dispatch}, transfer) {
            commit('set_detail', []);
            return await ReportsApi.getTransferDetail(transfer)
                        .then( response => {
                            commit('set_detail', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar detalle de transferencia, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async transferExcel({commit, dispatch}, filters) {
            commit('set_file_excel', null);
            return await ReportsApi.transferExcel(filters)
                        .then( response => {
                            commit('set_file_excel', response.data.file_excel);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al descargar reporte de transferencias, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async transferDetailExcel({commit, dispatch}, transfer) {
            commit('set_file_excel', null);
            return await ReportsApi.transferDetailExcel(transfer)
                        .then( response => {
                            commit('set_file_excel', response.data.file_excel);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al descargar detalle de transferencia, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
    }
}