import Api from './Api';

const END_POINT = 'stations';

export default {
    get_all(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}`, request);
    },
    async store(station) {
        return await Api.post(`${END_POINT}`, station)
    },
    async delete(station) {
        return await Api.delete(`${END_POINT}/${station.id}`, station)
    }
}