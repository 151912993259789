import CommissionsApi from '../../apis/Commissions';

export default {
    namespaced: true,
    state: {
        pending_commissions: [],
        commission_processed: [],
        commission_detail: []
    },
    mutations: {
        set_pending_commissions (state, data) {
            state.pending_commissions = data;
        },
        set_commission_processed (state, data) {
            state.commission_processed = data;
        },
        set_commission_detail (state, data) {
            state.commission_detail = data;
        }
    },
    actions: {
        setPendingCommissions ({ commit }, data) {
            commit('set_pending_commissions', data);
        },
        setCommissionProcessed ({ commit }, data) {
            commit('set_commission_processed', data);
        },
        async getToProcess({commit, dispatch}, filters) {
            return await CommissionsApi.getToProcess(filters)
                .then( response => {
                    commit('set_pending_commissions', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar comisiones pendientes de procesar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, commission) {
            return await CommissionsApi.store(commission)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getToProcess', { office_id: commission.office_id });
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async processCommision({dispatch}, commission) {
            return await CommissionsApi.processCommision(commission)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El cáculo se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getToProcess', { office_id: commission.office_id });
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al procesar cálculo, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getProcessed({commit, dispatch}, filters) {
            return await CommissionsApi.getProcessed(filters)
                .then( response => {
                    commit('set_commission_processed', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar comisiones, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async paymentCommission({dispatch}, commission) {
            return await CommissionsApi.paymentCommission(commission)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pago se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getProcessed', { office_id: commission.office_id });
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al guardar pago, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getCommissionDetail({commit, dispatch}, filters) {
            commit('set_commission_detail', []);
            return await CommissionsApi.getCommissionDetail(filters)
                .then( response => {
                    commit('set_commission_detail', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar detalle, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, commission) {
            return await CommissionsApi.delete(commission)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getToProcess', { office_id: commission.office_id });
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async payAllCommissions({dispatch}, commission) {
            return await CommissionsApi.payAllCommissions(commission)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pago se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getProcessed', { office_id: commission.office_id });
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al guardar pago, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}