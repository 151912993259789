import UserSessionsApi from '../../apis/UserSessions';

export default {
    namespaced: true,
    state: {
        user_sessions: [],
        filters: null,
        file_name: null
    },
    mutations: {
        set_user_sessions (state, data) {
            state.user_sessions = data;
        },
        set_filters (state, data) {
            state.filters = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        setFilters ({commit}, data) {
            commit('set_filters', data);
        },
        async getAllUserSessions({commit, dispatch}, filters) {
            return await UserSessionsApi.get_all(filters)
                .then( response => {
                    commit('set_user_sessions', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar las sesiones de usuario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadExcel({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await UserSessionsApi.downloadExcel(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de sesiones de usuario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}