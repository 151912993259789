import Api from './Api';

const END_POINT = 'vouchers';

export default {
    get_all(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}`, request);
    },
    store(voucher) {
        return Api.post(`${END_POINT}`, voucher);
    },
    update(voucher) {
        return Api.patch(`${END_POINT}/${voucher.id}`, voucher);
    },
    change_status(voucher) {
        return Api.patch(`${END_POINT}/${voucher.id}/change-status`, voucher);
    },
    delete(voucher) {
        return Api.delete(`${END_POINT}/${voucher.id}`, voucher);
    }
}