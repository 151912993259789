import Api from './Api';

const END_POINT = 'menus';

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request);
    },
}