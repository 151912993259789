import ReportsApi from '../../apis/Reports';

export default {
    namespaced: true,
    state: {
        movements: [],
        file_name: null,
        paginated: {},
        filtersPaginated:{
            name: null,
            sku: null,
            barcode: null,
            category_id: null,
            brand_id: null,
            unit_id: null,
            office_id: null
        }
    },
    mutations: {
        set_filter_paginated(state,filtersPaginated){
            state.filtersPaginated=filtersPaginated
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_movements (state, data) {
            state.movements = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            // console.log(filters)
            commit('set_filter_paginated',filters)
        },
        setMovements ({ commit }, data) {
            commit('set_movements', data);
        },
        async getKardexMovements({commit, dispatch}, filters) {
            commit('set_movements', []);
            return await ReportsApi.getKardexMovements(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_movements', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_movements', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_movements', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_movements', dataPaginated)
                        commit('set_paginated', {})
                    }
                    //commit('set_movements', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar consumos internos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadConsumption({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ReportsApi.downloadConsumption(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de consumo interno, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadDecrease({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ReportsApi.downloadDecrease(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de merma, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}