import Api from './Api'

const END_POINT = 'inventories'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },

    list(filters = null) {        
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = `${END_POINT}/list-paginated`
                else
                    url =  `${END_POINT}/list`
            } else {
                url =  `${END_POINT}/list`;
            }
        } else {
            url =  `${END_POINT}/list`;
        }
        return Api.get(`${url}`, request);
    },

    availability(product_id) {
        return Api.get(`${END_POINT}/${product_id}/availability`);
    },

    async show(inventory) {
        return await Api.get(`${END_POINT}/${inventory.id}`)
    },

    async get_imeis(inventory, filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/${inventory.id}/list-imeis`, request)
    },

    async getImeisChips() {
        return await Api.get(`${END_POINT}/imeis-chips`);
    },

    async catalogue(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/get-catalogue`, request)
    }, 

    async convert(data) {
        return await Api.post(`${END_POINT}/convert`, data);
    },

    async storeNewStock(data) {
        return await Api.post(`${END_POINT}/new-stock`, data);
    },

    async storeDomesticConsumption(data) {
        return await Api.post(`${END_POINT}/domestic-consumption`, data);
    },

    async storeDecrease(data) {
        return await Api.post(`${END_POINT}/decrease`, data);
    },

    download(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/download`, request);
    },

    async catalogueToSale(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/get-catalogue-to-sale`, request);
    }, 

    getProductsToTransfer(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/get-products-to-transfer`, request);
    }, 

    async getProductsFromOffice(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/get-products-from-office`, request)
    },

    async increaseStock(data) {
        return await Api.post(`${END_POINT}/increase-stock`, data);
    },

}