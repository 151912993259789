import OrdersApi from '../../apis/Orders';

export default {
    namespaced: true,
    state: {
        detail: [],
        total: null,
        orders: [],
        order_selected: null,
        igv: null,
        subtotal: null,
        discount: null,
        sale: null,
        file_name: null,
        rounding: null,
        total_rounded: null,
        total_gratuitas: null,

        paginated: {},
        filtersPaginated:{
            name: null,
            sku: null,
            barcode: null,
            category_id: null,
            brand_id: null,
            unit_id: null,
            office_id: null
        }
    },
    mutations: {
        set_filter_paginated(state,filtersPaginated){
            state.filtersPaginated=filtersPaginated
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },

        set_detail (state, data) {
            state.detail = data;
        },
        add_item_to_detail (state, item) {
            state.detail.push(item);
        },
        remove_item_from_detail (state, index) {
            state.detail.splice(index, 1);
        },
        replace_item_in_detail (state, data) {
            state.detail.splice(data.index, 1, data.item);
        },
        set_subtotal (state, value) {
            state.subtotal = value;
        },
        set_igv (state, value) {
            state.igv = value;
        },
        set_discount (state, value) {
            state.discount = value;
        },
        set_total (state, value) {
            state.total = value;
        },
        set_orders (state, data) {
            state.orders = data;
        },
        set_order_selected (state, data) {
            state.order_selected = data;
        },
        set_sale (state, data) {
            state.sale = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        },
        set_rounding (state, value) {
            state.rounding = value;
        },
        set_total_rounded (state, value) {
            state.total_rounded = value;
        },
        set_total_gratuitas (state, value) {
            state.total_gratuitas = value;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            // console.log(filters)
            commit('set_filter_paginated',filters)
        },

        setDetail ({commit}, data) {
            commit('set_detail', data);
        },
        addItemToOrder ({commit, dispatch}, item) {
            commit('add_item_to_detail', item);
            dispatch('calculateTotal');
        },
        deleteItemFromOrder ({commit, dispatch}, index) {
            commit('remove_item_from_detail', index);
            dispatch('calculateTotal');
        },
        replaceItemInOrder ({commit, dispatch}, data) {
            commit('replace_item_in_detail', data);
            dispatch('calculateTotal');
        },
        setTotal ({commit}, value) {
            commit('set_total', value);
        },
        clearAmount({commit}){
            commit('set_discount', null);
            commit('set_subtotal', null);
            commit('set_igv', null);
            commit('set_total', null);
            commit('set_rounding', null);
            commit('set_total_rounded', null);
            commit('set_total_gratuitas', null);
        },
        calculateTotal ({commit, state}) {
            let sum_discount= 0;
            let sum_subtotal = 0;
            let sum_igv = 0;
            let sum_total = 0;
            let sum_total_gratuitas = 0;

            state.detail.forEach( element => {
                if (element.is_bonus == true) {
                    sum_total_gratuitas = sum_total_gratuitas + parseFloat(element.subtotal);
                } else {
                    sum_subtotal = sum_subtotal + parseFloat(element.subtotal);
                }
            });

            sum_total = sum_subtotal;
            let amount_subtotal = Math.round((sum_subtotal / 1.18) * 100) / 100;
            sum_igv = Math.round((sum_total - amount_subtotal) * 100) / 100;

            sum_total = Math.round(sum_total * 100) / 100;

            //calculo de redondeo a 1 decimal
            let amount_total_rounded = Math.round(sum_total * 10) / 10;
            let amount_rounded = Math.round( amount_total_rounded * 100) / 100;
            let amount_rounding = Math.round((amount_rounded - sum_total) * 100) / 100;

            let amount_gratuitas= Math.round(sum_total_gratuitas * 100) / 100;

            commit('set_discount', sum_discount);
            commit('set_subtotal', amount_subtotal);
            commit('set_igv', sum_igv);
            commit('set_total', sum_total);

            commit('set_rounding', amount_rounding);
            commit('set_total_rounded', amount_rounded);

            commit('set_total_gratuitas', amount_gratuitas);
        },
        setOrderSelected ({commit}, data) {
            commit('set_order_selected', data);
            if (data) {
                commit('set_detail', data.detail);
                commit('set_discount', data.discount);
                commit('set_subtotal', data.subtotal);
                commit('set_igv', data.igv);
                commit('set_total', data.total);
                commit('set_rounding', data.rounding);
                commit('set_total_rounded', data.total_rounded);
                commit('set_total_gratuitas', data.total_gratuitas);
            } else {
                commit('set_detail', []);
                commit('set_discount', null);
                commit('set_subtotal', null);
                commit('set_igv', null);
                commit('set_total', null);
                commit('set_rounding', null);
                commit('set_total_rounded', null);
                commit('set_total_gratuitas', null);
            }
        },
        setSale ({commit}, data) {
            commit('set_sale', data);
        },
        async getOrders ({commit, dispatch}, filters) {
            //commit('set_orders', []);
            return await OrdersApi.get_all(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_orders', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_orders', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_orders', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_orders', dataPaginated)
                        commit('set_paginated', {})
                    }
                    //commit('set_orders', response.data);
                }).catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar pedidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store ({commit, dispatch}, order) {
            return await OrdersApi.store(order)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pedido se registró de forma correcta.'
                    }, {root: true});
                }).catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar pedido, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeSale({commit, dispatch}, sale) {
            return await OrdersApi.storeSale(sale)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La venta se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_sale', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar la venta, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async cancel ({commit, dispatch}, order) {
            return await OrdersApi.cancel(order)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pedido se anuló de forma correcta.'
                    }, {root: true});
                    dispatch('getOrders');
                }).catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al anular pedido, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadDetail ({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await OrdersApi.download_detail(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                }).catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar detalle, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async addDetail({commit, dispatch}, item) {
            return await OrdersApi.addDetail(item)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El producto de agregó de forma correcta al pedido.'
                    }, {root: true});
                    console.log(response.data);
                    commit('set_order_selected', response.data);
                    dispatch('setOrderSelected', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al modificar pedido, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async updateDetail({commit, dispatch}, item) {
            return await OrdersApi.updateDetail(item)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pedido se actualizó de forma correcta.'
                    }, {root: true});
                    commit('set_order_selected', response.data);
                    dispatch('setOrderSelected', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al modificar pedido, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async deleteDetail({commit, dispatch}, item) {
            return await OrdersApi.deleteDetail(item)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'Item eliminado de forma correcta.'
                    }, {root: true});
                    commit('set_order_selected', response.data);
                    dispatch('setOrderSelected', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar item del pedido, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadBySeller ({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await OrdersApi.download_by_seller(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                }).catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar detalle de abarrotes, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadEmbutidos ({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await OrdersApi.downloadEmbutidos(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                }).catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar detalle de embutidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}