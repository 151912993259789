import Api from './Api'

const END_POINT = 'dashboard';

export default {
    getMostSelledProducts(year, month) {
        let request = {
            params: {
                year_value: year,
                month_value: month                
            }
        }
        return Api.get(`${END_POINT}/most-selled-products`, request);
    },
    getSalesPerMonth(year) {
        let request = {
            params: {
                year_value: year              
            }
        }
        return Api.get(`${END_POINT}/sales-per-month`, request);
    },
    ventasProductoDiarioHoy(){
        return Api.get('productos-vendidos-hoy')//{fechaFin});
    },
    ventasProductoDiarioAyer(){
        return Api.get('productos-vendidos-ayer')
    },
    productosGanancias(){
        return Api.get('productos-ganancias')
    },
    cantidadProductosMes(){
        return Api.get('cant-productos-mes')
    },
    cantidadProductosMesPasado(){
        return Api.get('cant-productos-mes-pasado');
    },
    totalCantidad(){
        return Api.get('cant-total');
    },
    reporteProductosDiarios(mes){
        console.log('reporte-productos-anio/'+mes);
        return Api.get('reporte-productos-anio/'+mes);
    },
    ventasHoy(){
        return Api.get('ventas-hoy');
    },
    ventasAyer(){
        return Api.get('ventas-ayer');
    },
    totalesVentasDiarias(){
        return Api.get('totales-ventas-diarias');
    },
    ventasMes(){
        return Api.get('ventas-mes');
    },
    ventasMesPasado(){
        return Api.get('ventas-mes-pasado');
    },
    totalVentasMes(){
        return Api.get('ventas-anio');
    },
    rendimientoVentas(){
        return Api.get('rendimiento-ventas');
    },
    serviciosHoy(){
        return Api.get('servicios-diarios');
    },
    serviciosAyer(){
        return Api.get('servicios-diarios-ayer');
    },
    totalServicios(){
        return Api.get('total-servicios');
    },
    serviciosMesActual(){
        return Api.get('servicios-mes-actual');
    },
    serviciosMesPasado(){
        return Api.get('servicios-mes-pasado');
    },
    totalServiciosMes(){
        return Api.get('total-servicios-mes');
    },
    reporeServicios(mes){
        console.log('reporte-productos-anio/'+mes);
        return Api.get('reporte-servicios-anio/'+mes);
    },
    comprasMes(){
        return Api.get('compras-mes');
    },
    compraMesPasado(){
        return Api.get('compras-mes-pasado');
    },
    compraAnio(){
        return Api.get('compras-anio');
    },
    reporteCompras(){
        return Api.get('reporte-compras');
    }
}