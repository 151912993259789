import router from '@/router/index'

import Auth from '../../apis/Auth';
import UsersApi from '../../apis/Users';
import ArrayTools from '../../helpers/ArrayTools';

export default {
    namespaced: true,
    state: {
        token: null,
        select_office: false,
        user_companies: [],
        user_sedes: [],
        user_offices: [],
        office_id: null,
        current_employee: null,
        locked: false,
        auth_user: null,
        pin_unlock: null,
        company_id: null,
        sede_id: null,
        user_roles: null,
        till_user_id: null,
        user_permissions: [],
        wost_setting: null,
        office_login_name: null,
        show_menu: true,
        message_logout: null
    },
    mutations: {
        set_show_menu (state, value) {
            state.show_menu = value;
        },
        set_current_employee(state, data) {
            state.current_employee = data
        },
        set_token (state, token) {
            state.token = token
        },
        set_select_office (state, select_office) {
            state.select_office = select_office
        },
        set_user_companies(state, companies) {
            state.user_companies = companies
        },
        set_user_sedes(state, user_sedes) {
            state.user_sedes = user_sedes
        },
        set_user_offices(state, user_offices) {
            state.user_offices = user_offices
        },
        set_office_id(state, office_id) {
            state.office_id = office_id
        },
        set_locked(state, value) {
            state.locked = value;
        },
        set_auth_user(state, data) {
            state.auth_user = data;
        },
        set_pin_unlock(state, value){
            state.pin_unlock = value;
        },
        set_company_id(state, value){
            state.company_id = value;
        },
        set_sede_id(state, value){
            state.sede_id = value;
        },
        set_user_roles(state, data) {
            state.user_roles = data;
        },
        set_till_user_id(state, value) {
            state.till_user_id = value;
        },
        set_user_permissions(state, data) {
            state.user_permissions =  data;
        },
        set_wost_setting (state, data) {
            state.wost_setting = data;
        },
        set_office_login_name (state, value) {
            state.office_login_name = value;
        },
        set_message_logout (state, value) {
            state.message_logout = value;
        }
    },
    actions: {
        setShowMenu ({commit}, value) {
            commit('set_show_menu', value);
        },
        run_set_locked({commit}, value) {
            commit('set_locked', value);
        },
        async login ({commit, dispatch, state}, {username, password}) {
            let token_fcm = localStorage.getItem('token_fcm');
            return await Auth.login(username, password, token_fcm)
                .then( response => {
                    localStorage.setItem('access_token', 'Bearer ' + response.data.token);
                    commit('set_token', response.data.token);
                    commit('set_auth_user', response.data.user);
                    commit('set_select_office', response.data.select_office);
                    commit('set_user_companies', response.data.companies);
                    commit('set_user_sedes', response.data.sedes);
                    commit('set_user_offices', response.data.offices);
                    commit('set_current_employee', response.data.employee);
                    commit('set_locked', response.data.user.locked);
                    commit('set_pin_unlock', response.data.user.pin_unlock);
                    //datos de tienda
                    commit('set_company_id', response.data.company_id);
                    commit('set_sede_id', response.data.sede_id);
                    commit('set_office_id', response.data.office_id);
                    //roles y permisos
                    commit('set_user_roles', response.data.roles);
                    commit('set_user_permissions', response.data.permissions);
                    //caja de usuario
                    commit('set_till_user_id', response.data.till_user_id);
                    //configuraciones
                    commit('set_wost_setting', response.data.wost_setting);
                    commit('set_office_login_name', response.data.office_login_name);

                    if (state.select_office) {
                        router.push('/ingresar-sucursal');
                    } else {
                        router.push('/app/dashboard');
                    } return response;
                })
                .catch((error) => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: error.response.data.message
                    }, {root: true});
                    return error
                })
        },
        async set_office_login({commit, dispatch}, office_id) {
            return await Auth.set_office_login(office_id)
                    .then( response => {
                        commit('set_company_id', response.data.company_id);
                        commit('set_sede_id', response.data.sede_id);
                        commit('set_office_id', response.data.office_id);
                        //caja de usuario
                        commit('set_till_user_id', response.data.till_user_id);
                        commit('set_office_login_name', response.data.office_login_name);
                        router.push('/app/dashboard')
                    }).catch( error => {
                        dispatch('notifications/store_notification', {
                            type: 'error',
                            message: error.response.data.error
                        }, {root: true});
                    })
        },
        async lockSystem({commit}, user) {
            return await UsersApi.lock(user)
                    .then( response => {
                        commit('set_locked', response.data.locked);
                    }).catch( error => {
                        console.log(error)
                    })
        },
        async logout({commit, dispatch}) {
            return await Auth.logout()
                .then(reponse => {
                    commit('set_message_logout', reponse.data.message);
                    localStorage.removeItem('token_fcm');
                    localStorage.removeItem('access_token')
                    commit('set_token', null)
                    commit('set_select_office', false)
                    commit('set_current_employee', null);
                    commit('set_pin_unlock', null);
                    commit('set_till_user_id', null);
                    router.push('/');
                }).catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al cerrar sesión'
                    }, {root: true});
                });
        }
    },
    getters: {
        isLoggedIn(state) {
            return !!state.token;
        },
        hasPermission: (state) => (permission) => {
            if (state.user_roles == 'superadmin') {
                return true
            } else {
                return state.user_permissions.includes(permission)
            }
        },
        hasMenu: (state) => (menu) => {
            if (state.user_roles == 'superadmin') {
                return true
            } else {
                return ArrayTools.existMenuInPermission(state.user_permissions, menu)
            }
        },
        getUserRole(state) {
            return state.user_roles;
        },
        isSuperadmin(state){
            if (state.user_roles == 'superadmin') {
                return true;
            } else {
                return false;
            }
        }
    }
}