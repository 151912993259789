import TransfersApi from '../../apis/Transfers';

import router from '@/router/index';

export default {
    namespaced: true,
    state: {
        transfers: [],
        transfer: null,
        detail: [],
        paginated: {}
    },
    mutations: {

        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_transfer(state, data) {
            state.transfer = data
        },
        set_transfers(state, data) {
            state.transfers = data
        },
        set_detail(state, data) {
            state.detail = data
        }
    },
    actions: {
        empty_transfer({commit}){
            commit('set_transfer', null);
        },
        async getTransfers({commit, dispatch}, filters) {
            commit('set_transfers', [])
            return await TransfersApi.get_all(filters)
                            .then ( response => {
                                let dataPaginated = response.data
                                if (filters != undefined) {
                                    if (filters.paginated || filters.paginated != null) {
                                        if (filters.itemsPerPage!=-1){
                                            commit('set_transfers', dataPaginated.data)
                                            commit('set_paginated', {...dataPaginated})
                                        }else{
                                            commit('set_transfers', dataPaginated)
                                            commit('set_paginated', {})
                                        }

                                    } else {
                                        commit('set_transfers', dataPaginated)
                                        commit('set_paginated', {})
                                    }
                                } else {
                                    commit('set_transfers', dataPaginated)
                                    commit('set_paginated', {})
                                }
                                // commit('set_transfers', response.data)
                            } )
                            .catch (error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al listar transferencias, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async store({commit, dispatch},transfer) {
            return await TransfersApi.store(transfer)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro de tranferencia se realizó de forma correcta.'
                                }, {root: true});
                                commit('set_transfer', response.data)
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar transferencia, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async getDetail({commit, dispatch}, transfer) {
            commit('set_transfer', transfer)
            commit('set_detail', [])
            return await TransfersApi.detail(transfer)
                            .then ( response => {
                                commit('set_detail', response.data)
                            } )
                            .catch (error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al cargar detalle de transferencia, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async receive({commit, dispatch},transfer) {
            return await TransfersApi.receive(transfer)
                            .then( response => {
                                commit('set_transfer', response.data)
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La recepción de tranferencia se realizó de forma correcta.'
                                }, {root: true});

                                router.push('/app/logistica/transferencias');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al recepcionar la transferencia, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async cancel({commit, dispatch},transfer) {
            return await TransfersApi.cancel(transfer)
                            .then( response => {
                                commit('set_transfer', response.data);
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La transferecia fue anulada de forma correcta.'
                                }, {root: true});
                                dispatch('getTransfers');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al anular transferencia, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
    }
}