import Api from './Api'

const END_POINT = 'commissions';

export default {
    getToProcess(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/to-process`, request);
    },
    async store(commission) {
        return await Api.post(`${END_POINT}`, commission);
    },
    async processCommision(commission) {
        return await Api.post(`${END_POINT}/process`, commission);
    },
    getProcessed(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/processed`, request);
    },
    async paymentCommission(commission) {
        return await Api.patch(`${END_POINT}/payment`, commission);
    },
    getCommissionDetail(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/detail`, request);
    },
    async delete(commission) {
        return await Api.delete(`${END_POINT}/${commission.id}`);
    },
    async payAllCommissions(commission) {
        return await Api.post(`${END_POINT}/pay-all`, commission);
    },
}