import ReportsApi from '../../apis/Reports';

export default {
    namespaced: true,
    state: {
        services: [],
        file_name: null
    },
    mutations: {
        set_services (state, data) {
            state.services = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        setServices ({ commit }, data) {
            commit('set_services', data);
        },
        async getAttendedServices({commit, dispatch}, filters) {
            commit('set_services', []);
            return await ReportsApi.getAttendedServices(filters)
                .then( response => {
                    commit('set_services', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar servicios atendidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadAttendedServices({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ReportsApi.downloadAttendedServices(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de servicios atendidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}