import ServicesApi from '../../apis/Services';

export default {
    namespaced: true,
    state: {
        services: [],
        paginated: {},
        filtersPaginated:{
            name: null,
            sku: null,
            barcode: null,
            category_id: null,
            brand_id: null,
            unit_id: null,
            office_id: null
        }
    },
    mutations: {
        set_filter_paginated(state,filtersPaginated){
            state.filtersPaginated=filtersPaginated
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_services (state, data) {
            state.services = data;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            // console.log(filters)
            commit('set_filter_paginated',filters)
        },
        emptyServices ({ commit }) {
            commit('set_services', []);
        },
        async getServices({commit, dispatch}, filters) {
            console.log(' aqui esta la consola del storage');
            console.log(filters);
            return await ServicesApi.get_all(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                commit('set_services', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_services', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_services', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_services', dataPaginated)
                        commit('set_paginated', {})
                    }
                    //commit('set_services', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar servicios, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, service) {
            return await ServicesApi.store(service)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getServices');
                })
                .catch( error => {
                    let message = 'Ocurrió un error al registrar, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async update({dispatch}, service) {
            return await ServicesApi.update(service)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getServices');
                })
                .catch( error => {
                    let message = 'Ocurrió un error al actualizar, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async delete({dispatch}, service) {
            return await ServicesApi.delete(service)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getServices')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al intentar eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, service) {
            let action = (service.active) ? 'inactivación' : 'activación';
            return await ServicesApi.change_status(service)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getServices')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al intentar realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}