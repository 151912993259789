import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            // component: () => import('@/views/auth/Signin'),
            component: () => import('@/views/auth/Signin'),
            name: 'login'
        },
        {
            path: '/ingresar-sucursal',
            component: () => import('@/views/auth/SelectOffice'),
            name: 'ingresar-sucursal'
        },
        {
            path: '/app',
            component: () => import('@/layouts/MainLayout'),
            name: 'app',
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('@/views/dashboard/Dashboard'),
                    children: [
                        
                    ]
                },
                {
                    path: 'reportesProductos',
                    name: 'reportesProductos',
                    component: () => import('@/views/dashboard/reportes/DashboardProductos')
                },
                {
                    path: 'reportesVentas',
                    name: 'reportesVentas',
                    component: () => import('@/views/dashboard/reportes/DashboardVentas')
                },
                {
                    path: 'reportesCompras',
                    name: 'reportesCompras',
                    component: () => import('@/views/dashboard/reportes/DashboardCompras')
                },
                {
                    path: 'reportesServicios',
                    name: 'reportesServicios',
                    component: () => import('@/views/dashboard/reportes/DashboardServicios')
                },
                /*{
                    path: 'dashboardVentas',
                    name: 'dashboardVentas',
                    component: () => import('@/views/dashboard/DashboardProductos')
                },*/
                /*{
                    path: 'dashboardNuevo',
                    name: 'dashboardNuevo',
                    component: () => import('@/views/dashboard/DashboardNew')
                },
                {
                    path: 'dashboardPrueba',
                    name: 'dashboardPrueba',
                    component: () => import('@/views/dashboard/DashboardPrueba'),
                },*/
                {
                    path: 'configuracion',
                    name: 'configuracion',
                    component: () => import('@/views/configurations/index'),
                    children: [
                        {
                            path: 'empresas',
                            name: 'empresas',
                            component: () => import('@/views/configurations/companies/Companies')
                        },
                        {
                            path: 'sedes',
                            name: 'sedes',
                            component: () => import('@/views/configurations/sedes/Sedes')
                        },
                        {
                            path: 'sucursales',
                            name: 'sucursales',
                            component: () => import('@/views/configurations/sucursales/Offices')
                        },
                        {
                            path: 'comprobantes',
                            name: 'comprobantes',
                            component: () => import('@/views/configurations/vouchers/Vouchers')
                        },
                        {
                            path: 'cuentas-bancarias',
                            name: 'cuentas-bancarias',
                            component: () => import('@/views/configurations/accounts/Accounts')
                        },
                        {
                            path: 'estado-cuenta',
                            name: 'estado-cuenta',
                            component: () => import('@/views/configurations/accounts/DetailAccount')
                        },
                        {
                            path: 'impresoras',
                            name: 'impresoras',
                            component: () => import('@/views/configurations/printers/Printers')
                        },
                    ]
                },
                {
                    path: 'registros',
                    name: 'registros',
                    component: () => import('@/views/registers/index'),
                    children: [
                        {
                            path: 'categorias',
                            name: 'categorias',
                            component: () => import('@/views/registers/categories/Categories')
                        },
                        {
                            path: 'capacidades',
                            name: 'capacidades',
                            component: () => import('@/views/registers/capacities/Capacities')
                        },
                        {
                            path: 'colores',
                            name: 'colores',
                            component: () => import('@/views/registers/colors/Colors')
                        },
                        {
                            path: 'marcas',
                            name: 'marcas',
                            component: () => import('@/views/registers/brands/Brands')
                        },
                        {
                            path: 'tipos',
                            name: 'tipos',
                            component: () => import('@/views/registers/types/Types')
                        },
                        {
                            path: 'unidades',
                            name: 'unidades',
                            component: () => import('@/views/registers/units/Units')
                        }
                    ]
                },
                {
                    path: 'ventas',
                    name: 'ventas',
                    component: () => import('@/views/sales/index'),
                    children: [
                        {
                            path: 'clientes',
                            name: 'clientes',
                            component: () => import('@/views/sales/customers/Customers')
                        },
                        {
                            path: 'nuevo-pedido',
                            name: 'nuevo-pedido',
                            component: () => import('@/views/sales/orders/CreateOrder')
                        },
                        {
                            path: 'pedidos',
                            name: 'pedidos',
                            component: () => import('@/views/sales/orders/Orders')
                        },
                        {
                            path: 'detalle-pedido',
                            name: 'detalle-pedido',
                            component: () => import('@/views/sales/orders/DetailOrder')
                        },
                        {
                            path: 'nueva-venta',
                            name: 'nueva-venta',
                            component: () => import('@/views-updated/ventas/nueva-venta/NuevaVenta')
                        },
                        {
                            path: 'nueva-venta-2',
                            name: 'nueva-venta-2',
                            component: () => import('@/views/sales/newsale/Newsale')
                        },
                        {
                            path: 'orden-de-servicio',
                            name: 'orden-de-servicio',
                            component: () => import('@/views/sales/stations/Stations')
                        }
                    ]
                },
                {
                    path: 'cuentas',
                    name: 'cuentas',
                    component: () => import('@/views/debts/index'),
                    children: [
                        {
                            path: 'cuentas-por-cobrar',
                            name: 'cuentas-por-cobrar',
                            component: () => import('@/views/debts/customers/AccountsReceivable')
                        },
                        {
                            path: 'cuentas-por-pagar',
                            name: 'cuentas-por-pagar',
                            component: () => import('@/views/debts/suppliers/DebtSupplier')
                        },
                    ]
                },
                {
                    path: 'cajas',
                    name: 'cajas',
                    component: () => import('@/views/cash/index'),
                    children: [
                        {
                            path: 'cajas-registradoras',
                            name: 'cajas-registradoras',
                            component: () => import('@/views/cash/tills/Tills')
                        },
                        {
                            path: 'movimientos',
                            name: 'movimientos',
                            component: () => import('@/views/cash/tills/TillMovements')
                        },
                    ]
                },
                {
                    path: 'comisiones',
                    name: 'comisiones',
                    component: () => import('@/views/commissions/index'),
                    children: [
                        {
                            path: 'procesar-comisiones',
                            name: 'procesar-comisiones',
                            component: () => import('@/views/commissions/toprocess/ProcessCommissions')
                        },
                        {
                            path: 'comisiones-procesadas',
                            name: 'comisiones-procesadas',
                            component: () => import('@/views/commissions/processed/ProcessedCommissions')
                        }
                    ]
                },
                {
                    path: 'seguridad',
                    name: 'seguridad',
                    component: () => import('@/views/security/index'),
                    children: [
                        {
                            path: 'empleados',
                            name: 'empleados',
                            component: () => import('@/views/security/employees/Employees')
                        },
                        {
                            path: 'roles',
                            name: 'roles',
                            component: () => import('@/views/security/roles/Roles')
                        }
                    ]
                },
                {
                    path: 'logistica',
                    name: 'logistica',
                    component: () => import('@/views/logistics/index'),
                    children: [
                        {
                            path: 'areas',
                            name: 'areas',
                            component: () => import('@/views/logistics/areas/Areas')
                        },
                        {
                            path: 'productos',
                            name: 'productos',
                            component: () => import('@/views/logistics/products/Products')
                        },
                        {
                            path: 'datos-producto',
                            name: 'datos-producto',
                            component: () => import('@/views/logistics/products/ProductDetail')
                        },
                        {
                            path: 'proveedores',
                            name: 'proveedores',
                            component: () => import('@/views/logistics/suppliers/Suppliers')
                        },
                        {
                            path: 'compras',
                            name: 'compras',
                            component: () => import('@/views/logistics/purchases/Purchases')
                        },
                        {
                            path: 'almacenes',
                            name: 'almacenes',
                            component: () => import('@/views/logistics/storehouses/Storehouses')
                        },
                        {
                            path: 'almacen-insumo',
                            name: 'almacen-insumo',
                            component: () => import('@/views/logistics/storehouses/StorehousesInsumo')
                        },
                        {
                            path: 'kardex',
                            name: 'kardex',
                            component: () => import('@/views/logistics/storehouses/Kardex')
                        },
                        {
                            path: 'kardex-insumos',
                            name: 'kardex-insumos',
                            component: () => import('@/views/logistics/storehouses/kardexInsumos/')
                        },
                        {
                            path: 'detalle',
                            name: 'detalle',
                            component: () => import('@/views/logistics/storehouses/Detail')
                        },
                        {
                            path: 'transferencias',
                            name: 'transferencias',
                            component: () => import('@/views/logistics/transfers/Transfers')
                        },
                        {
                            path: 'nueva-transferencia',
                            name: 'nueva-transferencia',
                            component: () => import('@/views/logistics/transfers/NewTransfers')
                        },
                        {
                            path: 'recepcion-transferencia',
                            name: 'recepcion-transferencia',
                            component: () => import('@/views/logistics/transfers/ReceptionTransfer')
                        },
                        {
                            path: 'servicios',
                            name: 'servicios',
                            component: () => import('@/views/logistics/services/Services')
                        },
                        {
                            path: 'insumos',
                            name: 'insumos',
                            component: () => import('@/views/logistics/insumos/Insumos')
                        },
                        {
                            path: 'recetas',
                            name: 'recetas',
                            component: () => import('@/views/logistics/recetas/Recetas')
                        },
                        {
                            path: 'datos-recetas',
                            name: 'datos-recetas',
                            component: () => import('@/views/logistics/recetas/CreateUpdate')
                        },
                    ]
                },
                {
                    path: 'reportes',
                    name: 'reportes',
                    component: () => import('@/views/reports/index'),
                    children: [
                        {
                            path: 'reporte-compras',
                            name: 'reporte-compras',
                            component: () => import('@/views/reports/purchases/ReportPurchases')
                        },
                        {
                            path: 'detalle-compras',
                            name: 'detalle-compras',
                            component: () => import('@/views/reports/purchases/DetailPurchase')
                        },
                        {
                            path: 'reporte-ventas',
                            name: 'reporte-ventas',
                            component: () => import('@/views/reports/sales/ReportSales')
                        },
                        {
                            path: 'detalle-ventas',
                            name: 'detalle-ventas',
                            component: () => import('@/views/reports/sales/DetailSale')
                        },
                        {
                            path: 'reporte-notas-credito',
                            name: 'reporte-notas-credito',
                            component: () => import('@/views/reports/creditnotes/CreditNotes')
                        },
                        {
                            path: 'reporte-transferencias',
                            name: 'reporte-transferencias',
                            component: () => import('@/views/reports/transfers/ReportTransfers')
                        },
                        {
                            path: 'detalle-transferencias',
                            name: 'detalle-transferencias',
                            component: () => import('@/views/reports/transfers/DetailTransfers')
                        },
                        {
                            path: 'reporte-caja',
                            name: 'reporte-caja',
                            component: () => import('@/views/reports/tills/ReportTills')
                        },
                        {
                            path: 'detalle-caja',
                            name: 'detalle-caja',
                            component: () => import('@/views/reports/tills/DetailTill')
                        },
                        {
                            path: 'sesiones-usuario',
                            name: 'sesiones-usuario',
                            component: () => import('@/views/reports/users/UserSessions')
                        },
                        {
                            path: 'consumo-interno',
                            name: 'consumo-interno',
                            component: () => import('@/views/reports/kardex/ReportConsumption')
                        },
                        {
                            path: 'merma',
                            name: 'merma',
                            component: () => import('@/views/reports/kardex/ReportDecrease')
                        },
                        {
                            path: 'productos-vendidos',
                            name: 'productos-vendidos',
                            component: () => import('@/views/reports/sales/SoldProducts')
                        },
                        {
                            path: 'servicios-atendidos',
                            name: 'servicios-atendidos',
                            component: () => import('@/views/reports/sales/AttendedServices')
                        },
                    ]
                }
            ]
        }
    ]
})