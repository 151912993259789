import SuppliersApi from '../../apis/Suppliers'

export default {
    namespaced: true,
    state: {
        suppliers: [],
        paginated: {},
        filterPaginated: {
            name: null,
            ruc: null,
            email: null,
            phone: null,
            trade_name: null
        }
    },
    mutations: {
        set_filter_paginated(state,data){
            state.filterPaginated=data;
        },
        set_suppliers(state, suppliers){
            state.suppliers = suppliers
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            console.log(filters)
            commit('set_filter_paginated',filters)
        },
        async getSuppliers({commit, dispatch}, filters) {
            return await SuppliersApi.get_all(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage != -1) {
                                        commit('set_suppliers', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_suppliers', dataPaginated)
                                        commit('set_paginated', {})
                                    }
                                } else {
                                    commit('set_suppliers', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_suppliers', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_suppliers', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, supplier) {
            return await SuppliersApi.store(supplier)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSuppliers',{paginated:true})
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, supplier) {
            return await SuppliersApi.update(supplier)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getSuppliers')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, supplier) {
            let action = (supplier.active) ? 'inactivación' : 'activación';
            return await SuppliersApi.change_status(supplier)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSuppliers')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, supplier) {
            return await SuppliersApi.delete(supplier)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getSuppliers')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        }
    }
}