import UnitsApi from '../../apis/Units';

export default {
    namespaced: true,
    state: {
        units: [],
        paginated: {},
        filterPaginated:{name:null,description:null}
    },
    mutations: {
        set_units (state, data) {
            state.units = data;
        },
        set_paginated(state, paginated) {
            state.paginated=paginated
        },
        set_filter_paginated(state,data){
            state.filterPaginated=data;
        }
    },
    actions: {
        changeFilterPaginated({commit,dispatch},filters){
            console.log(filters)
            commit('set_filter_paginated',filters)
        },
        async getUnits({commit, dispatch}, filters) {
            return await UnitsApi.get_all(filters)
                .then( response => {
                    let dataPaginated = response.data
                    if (filters != undefined) {
                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage != -1) {
                                commit('set_units', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                commit('set_units', dataPaginated)
                                commit('set_paginated', {})
                            }
                        } else {
                            commit('set_units', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_units', dataPaginated)
                        commit('set_paginated', {})
                    }
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar unidades de medida, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, unit) {
            return await UnitsApi.store(unit)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La unidad se registró de forma correcta.'
                    }, {root: true});
                    dispatch('getUnits')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, unit) {
            return await UnitsApi.update(unit)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getUnits')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, unit) {
            let action = (unit.active) ? 'inactivación' : 'activación';
            return await UnitsApi.change_status(unit)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getUnits')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, unit) {
            return await UnitsApi.delete(unit)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getUnits')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}