import InventoriesApi from '../../apis/Inventories'
import KardexApi from '../../apis/Kardex'

import router from '@/router/index'

export default {
    namespaced: true,
    state: {
        main_storehouse: null,
        office_id: null,
        inventories: [],
        inventory: null,
        kardex: [],
        detail: null,
        office_name: null,
        sede_name: null,
        company_name: null,
        list_imeis: null,
        catalogue: [],
        imeis_chips: [],
        stores: [],
        office_filtered: null,
        filters: null,
        file_name: null,
        value_stock: null,
        inventory_selected: {},
        products: [],
        paginated: {},
        filtersPaginated: {
            main_storehouse: null,
            office_id: null,
            category_id: null,
            itemsPerPage: null,
            paginated: null,
            value_stock: null
        }
    },
    mutations: {
        set_filter_paginated(state, filtersPaginated) {
            state.filtersPaginated = filtersPaginated
        },
        set_list_imeis(state, data) {
            state.list_imeis = data
        },
        set_office_name(state, value) {
            state.office_name = value
        },
        set_sede_name(state, value) {
            state.sede_name = value
        },
        set_company_name(state, value) {
            state.company_name = value
        },
        set_detail(state, detail) {
            state.detail = detail
        },
        set_main_storehouse(state, main_storehouse) {
            state.main_storehouse = main_storehouse
        },
        set_office_id(state, office_id) {
            state.office_id = office_id
        },
        set_inventory(state, inventory) {
            state.inventory = inventory
        },
        set_inventories(state, inventories) {
            state.inventories = inventories
        },
        set_kardex(state, kardex) {
            state.kardex = kardex
        },
        set_catalogue(state, data) {
            state.catalogue = data
        },
        set_imeis_chips(state, data) {
            state.imeis_chips = data;
        },
        set_stores(state, data) {
            state.stores = data;
        },
        set_office_filtered(state, value) {
            state.office_filtered = value;
        },
        set_filters(state, data) {
            state.filters = data;
        },
        set_file_name(state, value) {
            state.file_name = value;
        },
        set_value_stock(state, value) {
            state.value_stock = value;
        },
        set_inventory_selected(state, data) {
            state.inventory_selected = data;
        },
        replace_inventory_item(state, data) {
            state.inventories.splice(data.index, 1, data.item);
        },
        set_products(state, data) {
            state.products = data;
        },
        set_paginated(state, data) {
            state.paginated = data
        }
    },
    actions: {
        changeFilterPaginated({commit, dispatch}, filters) {
            // console.log(filters)
            commit('set_filter_paginated', filters)
        },
        setProducts({commit}, data) {
            commit('set_products', data);
        },
        setInventories({commit}, data) {
            commit('set_inventories', data);
        },
        setFilters({commit}, data) {
            commit('set_filters', data);
        },
        run_set_catalogue({commit}, data) {
            commit('set_catalogue', data)
        },
        run_set_main_storehouse({commit}, value) {
            commit('set_main_storehouse', value)
        },
        run_set_office_id({commit}, value) {
            commit('set_office_id', value)
        },
        clean_data({commit}) {
            commit('set_main_storehouse', null)
            commit('set_inventory', null)
            commit('set_inventories', [])
            commit('set_office_name', null)
            commit('set_sede_name', null)
            commit('set_office_filtered', null);
        },
        clean_kardex({commit}) {
            commit('set_kardex', [])
        },
        run_select_inventory({commit}, inventory) {
            commit('set_inventory', inventory)
        },
        async getInventories({commit, dispatch}, filters) {
            return await InventoriesApi.get_all(filters)
                .then(response => {
                    commit('set_office_name', response.data.office_name)
                    commit('set_sede_name', response.data.sede_name)
                    commit('set_company_name', response.data.company_name)
                    commit('set_inventories', response.data.inventory)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getKardex({commit, dispatch}, filters) {
            dispatch('clean_kardex')
            return await KardexApi.get_all(filters)
                .then(response => {
                    commit('set_kardex', response.data)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar el kardex del producto, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getListImeis({commit, dispatch}, {inventory, filters}) {
            dispatch('clean_kardex')
            commit('set_list_imeis', [])
            return await InventoriesApi.get_imeis(inventory, filters)
                .then(response => {
                    commit('set_list_imeis', response.data)
                    if (filters == null) {
                        router.push('/app/logistica/detalle')
                    }
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar imeis del producto, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getCatalogue({commit, dispatch}, filters) {
            return await InventoriesApi.catalogue(filters)
                .then(response => {
                    commit('set_catalogue', response.data)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar catálogo de productos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getImeisChips({commit, dispatch}) {
            commit('set_imeis_chips', [])
            return await InventoriesApi.getImeisChips()
                .then(response => {
                    commit('set_imeis_chips', response.data);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar imeis de chips, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getList({commit, dispatch}, filters) {
            console.log(filters)
            return await InventoriesApi.list(filters)
                .then(response => {
                    let dataPaginated = response.data
                    console.log(dataPaginated)
                    if (filters != undefined) {

                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage != -1) {
                                console.log('paginado')
                                commit('set_inventories', dataPaginated.inventory.data)
                                commit('set_paginated', {...dataPaginated.inventory})
                            } else {
                                console.log('inventario en una sola pagina ')
                                commit('set_inventories', dataPaginated.inventory)
                                commit('set_paginated', {total: dataPaginated.inventory.length})
                            }

                        } else {
                            commit('set_inventories', dataPaginated.inventory)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_inventories', dataPaginated.inventory)
                        commit('set_paginated', {})
                    }
                    console.log('Respuesta de list de inventarios', dataPaginated)
                    commit('set_office_name', response.data.office_name)
                    commit('set_sede_name', response.data.sede_name)
                    commit('set_company_name', response.data.company_name)
                    //  commit('set_inventories', response.data.inventory)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getAvailability({commit, dispatch}, product_id) {
            return await InventoriesApi.availability(product_id)
                .then(response => {
                    commit('set_stores', response.data)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        selectOfficeFiltered({commit}, value) {
            commit('set_office_filtered', value);
        },
        async convert({dispatch}, data) {
            return await InventoriesApi.convert(data)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La conversión se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getList');
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar conversión, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeNewStock({commit, dispatch, state}, data) {
            return await InventoriesApi.storeNewStock(data)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de inventario se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_value_stock', 'Con Stock');
                    let filters = {
                        value_stock: state.value_stock
                    };
                    if (data.main_storehouse == true) {
                        filters['main_storehouse'] = true;
                    } else {
                        commit('set_office_filtered', data.office_id);
                    }
                    dispatch('getList', filters);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeDomesticConsumption({commit, dispatch, state}, data) {
            return await InventoriesApi.storeDomesticConsumption(data)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de consumo interno se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_value_stock', 'Con Stock');
                    let filters = {
                        value_stock: state.value_stock
                    };
                    if (data.main_storehouse == true) {
                        filters['main_storehouse'] = true;
                    } else {
                        commit('set_office_filtered', data.office_id);
                    }
                    dispatch('getList', filters);
                })
                .catch(error => {
                    let message = 'Ocurrió un error al registrar consumo interno, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async storeDecrease({commit, dispatch, state}, data) {
            return await InventoriesApi.storeDecrease(data)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de merma se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_value_stock', 'Con Stock');
                    let filters = {
                        value_stock: state.value_stock
                    };
                    if (data.main_storehouse == true) {
                        filters['main_storehouse'] = true;
                    } else {
                        commit('set_office_filtered', data.office_id);
                    }
                    dispatch('getList', filters);
                })
                .catch(error => {
                    let message = 'Ocurrió un error al registrar merma, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async download({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await InventoriesApi.download(filters)
                .then(response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getCatalogueToSale({commit, dispatch}, filters) {
            console.log(filters)
            return await InventoriesApi.catalogueToSale(filters)
                .then(response => {
                    commit('set_catalogue', response.data)
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar catálogo de productos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getProductsToTransfer({commit, dispatch}, filters) {
            return await InventoriesApi.getProductsToTransfer(filters)
                .then(response => {
                    commit('set_inventories', response.data);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar inventario, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        setInventorySelected({commit}, data) {
            commit('set_inventory_selected', data);
        },
        replaceInventoryItem({commit}, data) {
            commit('replace_inventory_item', data);
        },
        async getProductsFromOffice({commit, dispatch}, filters) {
            return await InventoriesApi.getProductsFromOffice(filters)
                .then(response => {
                    commit('set_products', response.data);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar productos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async increaseStock({commit, dispatch, state}, data) {
            return await InventoriesApi.increaseStock(data)
                .then(response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de aumento de stock se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_value_stock', 'Con Stock');
                    let filters = {
                        value_stock: state.value_stock
                    };
                    if (data.main_storehouse == true) {
                        filters['main_storehouse'] = true;
                    } else {
                        commit('set_office_filtered', data.office_id);
                    }
                    dispatch('getList', filters);
                })
                .catch(error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar aumento de stock, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}

