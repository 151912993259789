import TillsApi from '../../apis/Tills';

import ArrayTools from '../../helpers/ArrayTools';

export default {
    namespaced: true,
    state: {
        tills: [],
        selected_till: null,
        operations: [],
        total: null,
        number_operations: null,
        total_sales: null,
        number_sales: null,
        total_incomes: null,
        number_incomes: null,
        total_expenses: null,
        number_expenses: null,
        total_cash: null,
        number_cash: null,
        total_card: null,
        number_card: null,
        total_transfer: null,
        number_transfer: null,
        total_yape: null,
        number_ype: null,
        total_open: null,
        number_open: null,
        total_canceled: null,
        number_canceled: null,
        last_closed: null,
        amount_open: null
    },
    mutations: {
        set_tills(state, data){
            state.tills = data;
        },
        set_selected_till(state, data) {
            state.selected_till = data;
        },
        set_operations(state, data) {
            state.operations = data;
        },
        add_operation(state, data) {
            state.operations.push(data);
        },
        replace_item_delivery(state, index) {
            state.delivery_detail.splice(index, 1, state.delivery_item)
        },
        reeplace_till(state, data) {
            state.tills.splice(data.index, 1, data.till);
        },
        set_total(state, value) {
            state.total = value;
        },
        set_number_operations(state, value) {
            state.number_operations = value;
        },
        set_total_sales(state, value) {
            state.total_sales = value;
        },
        set_number_sales(state, value) {
            state.number_sales = value;
        },
        set_total_incomes(state, value) {
            state.total_incomes = value;
        },
        set_number_incomes(state, value) {
            state.number_incomes = value;
        },
        set_total_expenses(state, value) {
            state.total_expenses = value;
        },
        set_number_expenses(state, value) {
            state.number_expenses = value;
        },
        set_total_cash(state, value){
            state.total_cash = value;
        },
        set_number_cash (state, value) {
            state.number_cash = value;
        },
        set_total_card(state, value){
            state.total_card = value;
        },
        set_number_card (state, value) {
            state.number_card = value;
        },
        set_total_transfer (state, value) {
            state.total_transfer = value
        },
        set_number_transfer (state, value) {
            state.number_transfer = value;
        },
        set_total_yape (state, value) {
            state.total_yape = value;
        },
        set_number_yape (state, value) {
            state.number_yape = value;
        },
        set_total_open (state, value) {
            state.total_open = value;
        },
        set_number_open (state, value) {
            state.number_open = value;
        },
        set_total_canceled (state, value) {
            state.total_canceled = value;
        },
        set_number_canceled (state, value) {
            state.number_canceled = value;
        },
        set_last_closed (state, data) {
            state.last_closed = data;
        },
        set_amount_open (state, value) {
            state.amount_open = value;
        }
    },
    actions: {
        setAmountOpen ({ commit }, value) {
            commit('set_amount_open', value);
        },
        empty_operations({commit}) {
            commit('set_operations', []);
        },
        empty_tills({commit}) {
            commit('set_tills', []);
        },
        select_till({commit}, data) {
            commit('set_selected_till', data);
        },
        async getTills({commit, dispatch}, filters) {
            return await TillsApi.get_all(filters)
                        .then( response => {
                            commit('set_tills', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar cajas, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, till) {
            return await TillsApi.store(till)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getTills');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar caja, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, till) {
            return await TillsApi.update(till)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getTills');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar caja, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, till) {
            let action = (till.active) ? 'inactivación' : 'activación';
            return await TillsApi.change_status(till)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getTills');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, till) {
            return await TillsApi.delete(till)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getTills');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar caja, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async getAvailable({commit, dispatch}, filters) {
            return await TillsApi.available(filters)
                        .then( response => {
                            commit('set_tills', response.data);
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar cajas disponibles, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getOperations({commit, dispatch, state}, filters) {
            return await TillsApi.get_operations(state.selected_till, filters)
                        .then( response => {
                            commit('set_operations', response.data);
                            dispatch('calculateSummary');
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar los movimientos de caja, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async storeOperation({commit, dispatch, state}, operation) {
            return await TillsApi.store_operation(operation)
                            .then( response => {
                                let message = 'El movimiento de caja se realizó de forma correcta';
                                if (operation.type == 'cierre') {
                                    message = 'El cierre de caja se realizó de forma correcta';
                                } else if (operation.type == 'ingreso') {
                                    message = 'El ingreso a caja se realizó de forma correcta';
                                } else if (operation.type == 'egreso') {
                                    message = 'El egreso de caja se realizó de forma correcta';
                                } else if (operation.type == 'apertura') {
                                    message = 'La apertura de caja se realizó de forma correcta';
                                }
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: message
                                }, {root: true});
                                let till = response.data.tills[0];
                                let index = ArrayTools.getIndexById(state.tills, till.id);
                                if (index > -1) {
                                    let data = {
                                        index: index,
                                        till: till
                                    }
                                    commit('reeplace_till', data);
                                }
                                commit('set_selected_till', response.data.tills[0]);
                                dispatch('getOperations');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar movimiento de caja, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        empty_summary({commit}) {
            commit('set_total', null);
            commit('set_number_operations', null);

            commit('set_total_sales', null);
            commit('set_number_sales', null);

            commit('set_total_incomes', null);
            commit('set_number_incomes', null);

            commit('set_total_expenses', null);
            commit('set_number_expenses', null);

            commit('set_total_cash', null);
            commit('set_number_cash', null);

            commit('set_total_transfer', null);
            commit('set_number_transfer', null);

            commit('set_total_card', null);
            commit('set_number_card', null);

            commit('set_total_yape', null);
            commit('set_number_yape', null);

            commit('set_total_open', null);
            commit('set_number_open', null);

            commit('set_total_canceled', null);
            commit('set_number_canceled', null);
        },
        calculateSummary({state, commit}) {
            let amount_total = 0;
            let quantity_operations = 0;
            let amount_open = 0;
            let quantity_open = 0;
            let amount_income = 0;
            let quantity_income = 0;
            let amount_expense = 0;
            let quantity_expense = 0;
            let amount_cash = 0;
            let quantity_cash = 0;
            let amount_card = 0;
            let quantity_card = 0;
            let amount_transfer = 0;
            let quantity_transfer = 0;
            let amount_yape = 0;
            let quantity_yape = 0;
            let amount_canceled = 0;
            let quantity_canceled = 0;

            state.operations.forEach(element => {
                quantity_operations++;
                if (element.type == 'apertura') {
                    amount_open = amount_open + parseFloat(element.amount);
                    quantity_open++;
                }else if (element.type == 'ingreso') {
                    amount_income = amount_income + parseFloat(element.amount);
                    quantity_income++;
                }else if (element.type == 'egreso') {
                    amount_expense = amount_expense + parseFloat(element.amount);
                    quantity_expense++;
                }else if (element.type == 'efectivo') {
                    amount_cash = amount_cash + parseFloat(element.amount);
                    quantity_cash++;
                }else if (element.type == 'tarjeta') {
                    amount_card = amount_card + parseFloat(element.amount);
                    quantity_card++;
                }else if (element.type == 'transferencia') {
                    amount_transfer = amount_transfer + parseFloat(element.amount);
                    quantity_transfer++;
                }else if (element.type == 'yape') {
                    amount_yape = amount_yape + parseFloat(element.amount);
                    quantity_yape++;
                }else if (element.type == 'anulacion') {
                    amount_canceled = amount_canceled + parseFloat(element.amount);
                    quantity_canceled++;
                    if (element.payment_method == 'TARJETA') {
                        amount_card = amount_card - parseFloat(element.amount);
                    } else if (element.payment_method == 'CASH') {
                        amount_cash = amount_cash - parseFloat(element.amount);
                    } else if (element.payment_method == 'TRANSFERENCIA') {
                        amount_transfer = amount_transfer - parseFloat(element.amount);
                    } else if (element.payment_method == 'YAPE') {
                        amount_yape = amount_yape - parseFloat(element.amount);
                    }
                }
            });

            amount_total = amount_open + amount_income + amount_cash + amount_card + amount_transfer + amount_yape - (amount_expense);

            commit('set_total', amount_total);
            commit('set_number_operations', quantity_operations);

            commit('set_total_sales', amount_cash + amount_card + amount_transfer + amount_yape);
            commit('set_number_sales', quantity_cash + quantity_card + quantity_transfer + quantity_yape);

            commit('set_total_incomes', amount_income);
            commit('set_number_incomes', quantity_income);

            commit('set_total_expenses', amount_expense);
            commit('set_number_expenses', quantity_expense);

            commit('set_total_cash', amount_cash);
            commit('set_number_cash', quantity_cash);

            commit('set_total_card', amount_card);
            commit('set_number_card', quantity_card);

            commit('set_total_transfer', amount_transfer);
            commit('set_number_transfer', quantity_transfer);

            commit('set_total_yape', amount_yape);
            commit('set_number_yape', quantity_yape);

            commit('set_total_open', amount_open);
            commit('set_number_open', quantity_open);

            commit('set_total_canceled', amount_canceled);
            commit('set_number_canceled', quantity_canceled);
        },
        async deleteOperation({commit, dispatch, state}, operation) {
            return await TillsApi.delete_operation(operation)
                .then( response => {
                    let message = 'El movimiento de caja se realizó de forma correcta';
                    if (operation.type == 'ingreso') {
                        message = 'Eliminación de ingreso se realizó de forma correcta';
                    } else if (operation.type == 'egreso') {
                        message = 'Eliminación de egreso se realizó de forma correcta';
                    }
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: message
                    }, {root: true});
                    let till = response.data.tills[0];
                    let index = ArrayTools.getIndexById(state.tills, till.id);
                    if (index > -1) {
                        let data = {
                            index: index,
                            till: till
                        }
                        commit('reeplace_till', data);
                    }
                    commit('set_selected_till', response.data.tills[0]);
                    dispatch('getOperations');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar movimiento de caja, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getLastClosed({commit, dispatch}, filters) {
            commit('set_last_closed', null);
            return await TillsApi.get_last_closed(filters)
                .then( response => {
                    commit('set_last_closed', response.data);
                    if (response.data.amount_close_cash) {
                        commit('set_amount_open', response.data.amount_close_cash);
                    } else {
                        commit('set_amount_open', 0);
                    }
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al obtener ultima caja cerrada, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}