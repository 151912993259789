import ReportsApi from '../../apis/Reports';

export default {
    namespaced: true,
    state: {
        products: [],
        file_name: null,
        paginated:{}
    },
    mutations: {
        set_products (state, data) {
            state.products = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        },
        set_paginated(state,value){
            state.paginated=value
        }
    },
    actions: {
        setProducts ({ commit }, data) {
            commit('set_products', data);
        },
        async getSoldProducts({commit, dispatch}, filters) {
            commit('set_products', []);
            return await ReportsApi.getSoldProducts(filters)
                .then( response => {
                    let dataPaginated = response.data
                    console.log(dataPaginated)
                    if (filters != undefined) {

                        if (filters.paginated || filters.paginated != null) {
                            if (filters.itemsPerPage!=-1){
                                console.log('paginado')
                                commit('set_products', dataPaginated.data)
                                commit('set_paginated', {...dataPaginated})
                            }else{
                                console.log('inventario en una sola pagina ')
                                commit('set_products', dataPaginated)
                                commit('set_paginated', {})
                            }

                        } else {
                            commit('set_products', dataPaginated)
                            commit('set_paginated', {})
                        }
                    } else {
                        commit('set_products', dataPaginated)
                        commit('set_paginated', {})
                    }
                    // commit('set_products', response.data);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar productos vendidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async downloadSoldProducts({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await ReportsApi.downloadSoldProducts(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar reporte de productos vendidos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}