import RolesApi from '../../apis/Roles';
import MenusApi from '../../apis/Menus';

export default {
    namespaced: true,
    state: {
        roles: [],
        permissions_list: []
    },
    mutations: {
        set_roles(state, data){
            state.roles = data;
        },
        set_permissions_list(state, data){
            state.permissions_list = data
        }
    },
    actions: {
        async getPermissionsList({commit, dispatch}, filters) {
            return await MenusApi.get_all(filters)
                        .then( response => {
                            commit('set_permissions_list', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar permisos, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async getRoles({commit, dispatch}, filters) {
            return await RolesApi.get_all(filters)
                        .then( response => {
                            commit('set_roles', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar roles, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, role) {
            return await RolesApi.store(role)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getRoles')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, role) {
            return await RolesApi.update(role)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getRoles')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, role) {
            let action = (role.active) ? 'inactivación' : 'activación';
            return await RolesApi.change_status(role)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getRoles')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, role) {
            return await RolesApi.delete(role)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getRoles')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        }
    }
}