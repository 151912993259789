import SalesApi from '../../apis/Sales';
import ArrayTools from '../../helpers/ArrayTools';
import NumberTools from '../../helpers/NumberTools';

export default {
    namespaced: true,
    state: {
        cart: [],
        modality: null,
        product: null,
        subtotal: null,
        discount: null,
        igv: null,
        total: null,
        customer: null,
        sale: null,
        status_operation: false,
        imeis_selected: [],
        view_prices: false,
        type_operation: 'sale',
        orders: [],
        order: null,
        detail: [],
        date_invoice: null,
        rounding: null,
        total_rounded: null,
        total_gratuitas: null,
        customer_id: null,
        sale_station: null,
        worker: null,
        worker_id: null
    },
    mutations: {
        set_status_operation(state, value) {
            state.status_operation = value;
        },
        set_sale(state, data) {
            state.sale = data;
        },
        set_cart(state, data) {
            state.cart = data;
        },
        set_add_product_to_cart(state, product) {
            state.cart.push(product);
        },
        set_modality(state, data) {
            state.modality = data;
        },
        set_product(state, data) {
            state.product = data;
        },
        set_subtotal(state, value) {
            state.subtotal = value;
        },
        set_igv(state, value) {
            state.igv = value;
        },
        set_total(state, value) {
            state.total = value;
        },
        delete_item_cart(state, index) {
            state.cart.splice(index, 1);
        },
        replace_item_cart(state, data) {
            state.cart.splice(data.index, 1, data.product)
        },
        set_customer(state, data){
            state.customer = data;
        },
        set_imeis_selected(state, data){
            state.imeis_selected = data;
        },
        add_item_imei(state, value) {
            state.imeis_selected.push(value);
        },
        delete_item_imei(state, index) {
            state.imeis_selected.splice(index, 1);
        },
        set_view_prices (state, value) {
            state.view_prices = value
        },
        set_discount (state, value) {
            state.discount = value;
        },
        set_type_operation (state, value) {
            state.type_operation = value;
        },
        set_orders (state, data) {
            state.orders = data;
        },
        set_order (state, data) {
            state.order = data;
        },
        set_detail (state, data) {
            state.detail = data;
        },
        set_date_invoice (state, value) {
            state.date_invoice = value;
        },
        set_rounding (state, value) {
            state.rounding = value;
        },
        set_total_rounded (state, value) {
            state.total_rounded = value;
        },
        set_total_gratuitas (state, value) {
            state.total_gratuitas = value;
        },
        set_customer_id (state, value) {
            state.customer_id = value;
        },
        set_sale_station (state, value) {
            state.sale_station = value;
        },
        set_worker (state, data) {
            state.worker = data;
        },
        set_worker_id (state, value) {
            state.worker_id = value;
        }
    },
    actions: {
        setViewPrices ({commit}, value) {
            commit('set_view_prices', value)
        },
        run_set_status_operation({commit}, value){
            commit('set_status_operation', value);
        },
        run_set_sale({commit}, data){
            commit('set_sale', data);
        },
        clearData({commit}){
            commit('set_cart', []);
            commit('set_customer', null);
            commit('set_customer_id', null);
            commit('set_discount', null);
            commit('set_subtotal', null);
            commit('set_igv', null);
            commit('set_total', null);
            commit('set_rounding', null);
            commit('set_total_rounded', null);
            commit('set_total_gratuitas', null);
            commit('set_sale_station', null);
            commit('set_worker', null);
            commit('set_worker_id', null);
        },
        clearAmount({commit}){
            commit('set_customer', null);
            commit('set_customer_id', null);
            commit('set_discount', null);
            commit('set_subtotal', null);
            commit('set_igv', null);
            commit('set_total', null);
            commit('set_rounding', null);
            commit('set_total_rounded', null);
            commit('set_total_gratuitas', null);
            commit('set_sale_station', null);
            commit('set_worker', null);
            commit('set_worker_id', null);
        },
        select_customer({commit}, customer) {
            commit('set_customer', customer);
            if (customer) {
                commit('set_customer_id', customer.id);
            } else {
                commit('set_customer_id', null);
            }            
        },
        selectWorker ({ commit }, worker) {
            commit('set_worker', worker);
            if (worker) {
                commit('set_worker_id', worker.id);
            } else {
                commit('set_worker_id', null);
            } 
        },
        setDateInvoice ({commit}, value) {
            commit('set_date_invoice', value);
        },
        add_product_to_cart({commit, dispatch}, product) {
            commit('set_add_product_to_cart', product);
            dispatch('calulateAmounts');
        },
        delete_product_cart({commit, dispatch, state}, product) {
            let index = ArrayTools.getIndexById(state.cart, product.id, product.type_item);
            commit('delete_item_cart', index);
            dispatch('calulateAmounts');
        },
        replace_product_cart({commit, dispatch, state}, product) {
            let index = ArrayTools.getIndexById(state.cart, product.id, product.type_item);
            let data = {
                index: index,
                product: product
            }
            commit('replace_item_cart', data)
            dispatch('calulateAmounts');
        },
        run_set_cart({commit}, data) {
            commit('set_cart', data)
        },
        select_modality({commit}, data) {
            commit('set_modality', data)
        },
        select_product({commit}, data) {
            commit('set_product', data)
        },
        calulateAmounts({commit, state}) {
            let sum_discount= 0;
            let sum_subtotal = 0;
            let sum_igv = 0;
            let sum_total = 0;
            let sum_total_gratuitas = 0;

            state.cart.forEach(element => {
                if (element.is_bonus == true) {
                    sum_total_gratuitas = sum_total_gratuitas + parseFloat(element.subtotal);
                } else {
                    sum_subtotal = sum_subtotal + parseFloat(element.subtotal) - NumberTools.getNumberDecimalValue(element.discount_value);
                    sum_discount = sum_discount + NumberTools.getNumberDecimalValue(element.discount_value);
                }
            });

            sum_total = sum_subtotal;
            let amount_subtotal = Math.round((sum_subtotal / 1.18) * 100) / 100;
            sum_igv = Math.round((sum_total - amount_subtotal) * 100) / 100;

            //calculo de redondeo a 1 decimal
            let amount_total_rounded = Math.round(sum_total * 10) / 10;
            let amoutn_rounded = Math.round( amount_total_rounded * 100) / 100;
            let amount_rounding = Math.round((amoutn_rounded - sum_total) * 100) / 100;

            let amount_gratuitas= Math.round(sum_total_gratuitas * 100) / 100;


            commit('set_discount', sum_discount);
            commit('set_subtotal', amount_subtotal);
            commit('set_igv', sum_igv);
            commit('set_total', sum_total);

            commit('set_rounding', amount_rounding);
            commit('set_total_rounded', amoutn_rounded);
            commit('set_total_gratuitas', amount_gratuitas);
        },
        async storeSale({commit, dispatch}, sale) {
            return await SalesApi.store(sale)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La venta se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_status_operation', true);
                    commit('set_sale', response.data);
                    dispatch('clearData');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar la venta, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        setTypeOperation ({commit}, value) {
            commit('set_type_operation', value);
        },
        async getOrders({commit, dispatch}, filters) {
            return await SalesApi.get_orders(filters)
                .then( response => {
                    commit('set_orders', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar cuentas por cobrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        selectOrder({commit}, data) {
            commit('set_order', data);
        },
        async storeAmortize({dispatch}, payment) {
            return await SalesApi.amortize(payment)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El pago se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getOrders');                      
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar pago, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeCreditNote({dispatch}, creditNote) {
            return await SalesApi.storeCreditNote(creditNote)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La anulación de la venta se realizó de forma correcta.'
                    }, {root: true});
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al anular la venta, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async getSaleByStation({commit, dispatch}, filters) {
            return await SalesApi.get_by_station(filters)
                .then( response => {
                    commit('set_sale_station', response.data.id);
                    commit('set_cart', response.data.detail);
                    commit('set_customer_id', response.data.customer_id);
                    commit('set_customer', response.data.customer);
                    commit('set_worker', response.data.worker);
                    commit('set_worker_id', response.data.worker_id);
                    if (response.data.date_invoice) {
                        commit('set_date_invoice', response.data.date_invoice);
                    }                    
                    dispatch('calulateAmounts');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al obtener datos de venta, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storeByStation({commit, dispatch}, sale) {
            return await SalesApi.storeByStation(sale)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'Los datos se guardaron de forma correcta.'
                    }, {root: true});
                    commit('set_status_operation', true);
                    dispatch('clearData');
                    dispatch('stations/selectStation', null, {root: true});
                    dispatch('stations/getStations', {office_id: sale.office_id}, {root: true});
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al guardar datos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async updateByStation({commit, dispatch}, sale) {
            return await SalesApi.updateByStation(sale)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'Los datos se guardaron de forma correcta.'
                    }, {root: true});
                    commit('set_status_operation', true);
                    dispatch('clearData');
                    dispatch('stations/selectStation', null, {root: true});
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al guardar datos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async storePaymentByStation({commit, dispatch}, sale) {
            return await SalesApi.storePaymentByStation(sale)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La venta se realizó de forma correcta.'
                    }, {root: true});
                    commit('set_status_operation', true);
                    commit('set_sale', response.data);
                    dispatch('clearData');
                    dispatch('stations/selectStation', null, {root: true});
                    dispatch('stations/getStations', {office_id: sale.office_id}, {root: true}); 
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar la venta, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}