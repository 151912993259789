import Api from './Api';

const END_POINT = 'tills';

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request);
    },
    async store(till) {
        return await Api.post(`${END_POINT}`, till);
    },
    async update(till) {
        return await Api.patch(`${END_POINT}/${till.id}`, till);
    },
    async change_status(till) {
        return await Api.patch(`${END_POINT}/${till.id}/change-status`, till);
    },
    async delete(till) {
        return await Api.delete(`${END_POINT}/${till.id}`, till);
    },
    async available(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/available`, request);
    },
    async get_operations(till, filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/${till.id}/operations`, request);
    },
    async store_operation(operation) {
        return await Api.post(`${END_POINT}/store-operation`, operation);
    },
    async delete_operation(operation) {
        return await Api.post(`${END_POINT}/delete-operation`, operation);
    },
    async get_last_closed(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}/get-last-closed`, request);
    },
}