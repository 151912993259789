import store from '@/store';

export default {
    install(Vue, options) {
        Vue.prototype.$hasPermision = function(permision) {
            let has = store.getters && store.getters['authentication/hasPermission'](permision)

            return has
        }

        Vue.prototype.$userRole = function() {
            return store.getters['authentication/getUserRole']
        }

        Vue.prototype.$hasMenu = function(menu) {
            let has = store.getters && store.getters['authentication/hasMenu'](menu)

            return has
        }
    }
}