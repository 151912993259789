import Api from './Api'

const END_POINT = 'offices'

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(office) {
        return await Api.post(`${END_POINT}`, office)
    },
    async update(office) {
        return await Api.patch(`${END_POINT}/${office.id}`, office)
    },
    async change_status(office) {
        return await Api.patch(`${END_POINT}/${office.id}/change-status`, office)
    },
    async delete(office) {
        return await Api.delete(`${END_POINT}/${office.id}`, office)
    },
    async available() {
        return await Api.get(`${END_POINT}/available`)
    }
}