import Api from './Api';

const END_POINT = 'services';

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        let url = ''
        console.log('esta es las respuesta en  al api');
        console.log(filters);
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'services-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
        //return await Api.get(`${END_POINT}`, request);
    },
    async store(service) {
        return await Api.post(`${END_POINT}`, service);
    },
    async update(service) {
        return await Api.patch(`${END_POINT}/${service.id}`, service);
    },
    async change_status(service) {
        return await Api.patch(`${END_POINT}/${service.id}/change-status`, service);
    },
    async delete(service) {
        return await Api.delete(`${END_POINT}/${service.id}`, service);
    },
}