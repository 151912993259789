import Api from './Api'

const END_POINT = 'banks';

export default {
    get_all(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}`, request);
    },
    store(bank) {
        return Api.post(`${END_POINT}`, bank);
    },
    update(bank) {
        return Api.patch(`${END_POINT}/${bank.id}`, bank);
    },
    change_status(bank) {
        return Api.patch(`${END_POINT}/${bank.id}/change-status`, bank);
    },
    delete(bank) {
        return Api.delete(`${END_POINT}/${bank.id}`, bank);
    }
}