import CompaniesApi from '../../apis/Companies'

export default {
    namespaced: true,
    state: {
        companies: []
    },
    mutations: {
        set_companies (state, companies) {
            state.companies = companies
        }
    },
    actions: {
        async getCompanies({commit}, filters = null) {
            return await CompaniesApi.get_all(filters)
                        .then( response => {
                            commit('set_companies', response.data)
                        })
                        .catch( error => {
                            console.log(error)
                        })
        },
        async store({dispatch}, company) {
            return await CompaniesApi.store(company)
                            .then( response => {
                                dispatch('getCompanies');
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, company) {
            return await CompaniesApi.update(company)
                            .then( response => {
                                dispatch('getCompanies');
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, company) {
            let action = (company.active) ? 'inactivación' : 'activación';
            return await CompaniesApi.change_status(company)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getCompanies');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, company) {
            return await CompaniesApi.delete(company)
                            .then( response => {                                
                                dispatch('getCompanies');
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        }
    }
}