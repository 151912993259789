import AreasApi from '../../apis/Areas';

export default {
    namespaced: true,
    state: {
        areas: []
    },
    mutations: {
        set_areas (state, data) {
            state.areas = data;
        }
    },
    actions: {
        async getAreas({commit, dispatch}, filters) {
            return await AreasApi.get_all(filters)
                .then( response => {
                    commit('set_areas', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, area) {
            return await AreasApi.store(area)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAreas')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, area) {
            return await AreasApi.update(area)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getAreas')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, area) {
            let action = (area.active) ? 'inactivación' : 'activación';
            return await AreasApi.change_status(area)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAreas')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, area) {
            return await AreasApi.delete(area)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getAreas')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}