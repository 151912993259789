import StationsApi from '../../apis/Stations';

export default {
    namespaced: true,
    state: {
        stations: [],
        selected_station: null
    },
    mutations: {
        set_stations (state, data) {
            state.stations = data;
        },
        set_selected_station (state, data) {
            state.selected_station = data;
        }
    },
    actions: {
        selectStation ({commit}, data) {
            commit('set_selected_station', data);
        },
        async getStations({commit, dispatch}, filters) {
            return await StationsApi.get_all(filters)
                .then( response => {
                    commit('set_stations', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar estaciones de servicio, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store({dispatch}, station) {
            return await StationsApi.store(station)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de estación de servicio se realizó de forma correcta.'
                    }, {root: true});
                    let filters = {
                        office_id: station.office_id
                    }
                    dispatch('getStations', filters);
                })
                .catch( error => {
                    let message = 'Ocurrió un error al registrar estación de servicio, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async delete({dispatch}, station) {
            return await StationsApi.delete(station)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación de estación de servicio se realizó de forma correcta.'
                    }, {root: true});
                    let filters = {
                        office_id: station.office_id
                    }
                    dispatch('getStations', filters);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar estación de servicio, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}