import Api from './Api'

export default {
    async login(username, password, token_fcm = null){
        let params = {
            username: username,
            password: password,
            token_fcm: token_fcm
        }

        return await Api.post('login', params)
    },

    async set_office_login(office_id) {
        let params = {
            office_id: office_id
        }

        return await Api.post('set-office-login', params)
    },

    async logout(){
        return await Api.patch('logout');
    },
}