import Api from './Api'

const END_POINT = 'types-documents'

export default {
    async get_all(filters = null) {       
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    }
}