import VouchersApi from '../../apis/Vouchers';

export default {
    namespaced: true,
    state: {
        vouchers: []
    },
    mutations: {
        set_vouchers (state, data) {
            state.vouchers = data;
        }
    },
    actions: {
        async getVouchers ({commit, dispatch}, filters) {
            return VouchersApi.get_all(filters)
                .then (response => {
                    commit('set_vouchers', response.data);
                })
                .catch (error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar comprobantes, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async store ({dispatch}, voucher) {
            return VouchersApi.store(voucher)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro de comprobante se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getVouchers');
                }).catch (error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al registrar comprobante, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async update({dispatch}, voucher) {
            return VouchersApi.update(voucher)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La actualización de comprobante se realizó de forma correcta'
                    }, {root: true});
                    dispatch('getVouchers');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al actualizar comprobante, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async change_status({dispatch}, voucher) {
            let action = (voucher.active) ? 'inactivación' : 'activación';
            return VouchersApi.change_status(voucher)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La ' + action + ' de comprobante se relizó de forma correcta.'
                    }, {root: true});
                    dispatch('getVouchers')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al realizar la ' + action + ' de comprobante, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        async delete({dispatch}, voucher) {
            return VouchersApi.delete(voucher)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación de comprobante se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getVouchers');
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar comprobante, vuelva a intentarlo.'
                    }, {root: true});
                })
        }
    }
}