import BanksApi from '../../apis/Banks';

export default {
    namespaced: true,
    state: {
        banks: []
    },
    mutations: {
        set_banks (state, data) {
            state.banks = data;
        }
    },
    actions: {
        async getBanks({commit, dispatch}, filters) {
            return await BanksApi.get_all(filters)
                .then( response => {
                    commit('set_banks', response.data)
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al listar bancos, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}