import Api from './Api'

const END_POINT = 'brands'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'brands-paginated'
                else
                    url = END_POINT
            }else{
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request)
    },
    async store(brand) {
        return await Api.post(`${END_POINT}`, brand)
    },
    async update(brand) {
        return await Api.patch(`${END_POINT}/${brand.id}`, brand)
    },
    async change_status(brand) {
        return await Api.patch(`${END_POINT}/${brand.id}/change-status`, brand)
    },
    async delete(brand) {
        return await Api.delete(`${END_POINT}/${brand.id}`, brand)
    }
}