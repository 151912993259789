import CustomersApi from '../../apis/Customers'

export default {
    namespaced: true,
    state: {
        customers: [],
        customer_selected: {},
        person: null,
        file_name: null,
        paginated:{},
        filtersPaginated:{
            type_document_id: null,
            document: null,
            name: null,
            surname: null,
            email: null
        }
    },
    mutations: {
        set_filter_paginated(state, filtersPaginated) {
            state.filtersPaginated = filtersPaginated
        },
        set_paginated(state,paginated){state.paginated=paginated},
        set_customers(state, customers){
            state.customers = customers
        },
        set_customer (state, customer) {
            state.customer_selected = customer;
        },
        set_person (state, data) {
            state.person = data;
        },
        set_file_name (state, value) {
            state.file_name = value;
        }
    },
    actions: {
        changeFilterPaginated({commit, dispatch}, filters) {
            // console.log(filters)
            commit('set_filter_paginated', filters)
        },
        setCustomer ({commit}, customer) {
            commit('set_customer', customer);
        },
        async getCustomers({commit, dispatch}, filters) {
            return await CustomersApi.get_all(filters)
                        .then( response => {
                            let dataPaginated = response.data
                            if (filters != undefined) {
                                if (filters.paginated || filters.paginated != null) {
                                    if (filters.itemsPerPage!=-1){
                                        commit('set_customers', dataPaginated.data)
                                        commit('set_paginated', {...dataPaginated})
                                    }else{
                                        commit('set_customers', dataPaginated)
                                        commit('set_paginated', {})
                                    }

                                } else {
                                    commit('set_customers', dataPaginated)
                                    commit('set_paginated', {})
                                }
                            } else {
                                commit('set_customers', dataPaginated)
                                commit('set_paginated', {})
                            }
                            // commit('set_customers', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar clientes, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, customer) {
            return await CustomersApi.store(customer)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'El registro se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getCustomers',{paginated:true});
                    dispatch('sales/select_customer', response.data, {root: true});
                })
                .catch( error => {
                    let message = 'Ocurrió un error al registrar cliente, vuelva a intentarlo.';
                    if (error.response.data && error.response.data.custom_error) {
                        message = error.response.data.custom_error;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async update({dispatch}, customer) {
            return await CustomersApi.update(customer)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getCustomers');
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar cliente, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, customer) {
            let action = (customer.active) ? 'inactivación' : 'activación';
            return await CustomersApi.change_status(customer)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getCustomers')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, customer) {
            return await CustomersApi.delete(customer)
                .then( response => {
                    dispatch('notifications/store_notification', {
                        type: 'success',
                        message: 'La eliminación se realizó de forma correcta.'
                    }, {root: true});
                    dispatch('getCustomers')
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
        emptyPerson({commit}) {
            commit('set_person', null);
        },
        async searchDocument({commit, dispatch}, document) {
            return await CustomersApi.search_document(document)
                .then( response => {
                    commit('set_person', response.data);
                })
                .catch( error => {
                    let message = 'No se encontró número de documento';
                    if (error.response.data.error_message) {
                        message = error.response.data.error_message;
                    }
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: message
                    }, {root: true});
                })
        },
        async downloadCustomers({commit, dispatch}, filters) {
            commit('set_file_name', null);
            return await CustomersApi.download(filters)
                .then( response => {
                    commit('set_file_name', response.data.file_name);
                })
                .catch( error => {
                    dispatch('notifications/store_notification', {
                        type: 'error',
                        message: 'Ocurrió un error al descargar, vuelva a intentarlo.'
                    }, {root: true});
                })
        },
    }
}