import PlansApi from '../../apis/Plans'

export default {
    namespaced: true,
    state: {
        plans: []
    },
    mutations: {
        set_plans(state, plans){
            state.plans = plans
        }
    },
    actions: {
        async getPlans({commit}, filters) {
            return await PlansApi.get_all(filters)
                        .then( response => {
                            commit('set_plans', response.data)
                        })
                        .catch( error => {
                            console.log(error)
                        })
        },
        async store({dispatch}, plan) {
            return await PlansApi.store(plan)
                            .then( response => {
                                console.log(response)
                                dispatch('getPlans')
                            })
                            .catch( error => {
                                console.log(error)
                            })
        },
        async update({dispatch}, plan) {
            return await PlansApi.update(plan)
                            .then( response => {
                                console.log(response)
                                dispatch('getPlans')
                            })
                            .catch( error => {
                                console.log(error)
                            })
        },
        async change_status({dispatch}, plan) {
            return await PlansApi.change_status(plan)
                            .then( response => {
                                console.log(response)
                                dispatch('getPlans')
                            })
                            .catch( error => {
                                console.log(error)
                            })
        },
        async delete({dispatch}, plan) {
            return await PlansApi.delete(plan)
                            .then( response => {
                                console.log(response)
                                dispatch('getPlans')
                            })
                            .catch( error => {
                                console.log(error)
                            })
        }

    }
}