import Api from './Api'

const END_POINT = 'categories'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'categories-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request)
    },
    async store(category) {
        return await Api.post(`${END_POINT}`, category)
    },
    async update(category) {
        return await Api.patch(`${END_POINT}/${category.id}`, category)
    },
    async change_status(category) {
        return await Api.patch(`${END_POINT}/${category.id}/change-status`, category)
    },
    async delete(category) {
        return await Api.delete(`${END_POINT}/${category.id}`, category)
    }
}