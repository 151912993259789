import OfficesApi from '../../apis/Offices'

export default {
    namespaced: true,
    state: {
        offices: [],
        offices_availables: []
    },
    mutations: {
        set_offices(state, offices){
            state.offices = offices
        },
        set_offices_availables(state, offices) {
            state.offices_availables = offices
        }
    },
    actions: {
        async run_set_offices({commit}, data){
            commit('set_offices', data);
        },
        async getOffices({commit, dispatch}, filters) {
            return await OfficesApi.get_all(filters)
                        .then( response => {
                            commit('set_offices', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar registros, vuelva a intentarlo.'
                            }, {root: true});
                        })
        },
        async store({dispatch}, office) {
            return await OfficesApi.store(office)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'El registro se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getOffices')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al registrar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async update({dispatch}, office) {
            return await OfficesApi.update(office)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La actualización se realizó de forma correcta'
                                }, {root: true});
                                dispatch('getOffices')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al actualizar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async change_status({dispatch}, office) {
            let action = (office.active) ? 'inactivación' : 'activación';
            return await OfficesApi.change_status(office)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La ' + action + ' se relizó de forma correcta.'
                                }, {root: true});
                                dispatch('getOffices')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al realizar la ' + action + ', vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async delete({dispatch}, office) {
            return await OfficesApi.delete(office)
                            .then( response => {
                                dispatch('notifications/store_notification', {
                                    type: 'success',
                                    message: 'La eliminación se realizó de forma correcta.'
                                }, {root: true});
                                dispatch('getOffices')
                            })
                            .catch( error => {
                                dispatch('notifications/store_notification', {
                                    type: 'error',
                                    message: 'Ocurrió un error al eliminar, vuelva a intentarlo.'
                                }, {root: true});
                            })
        },
        async getAvailables({commit, dispatch}) {
            return await OfficesApi.available()
                        .then( response => {
                            commit('set_offices_availables', response.data)
                        })
                        .catch( error => {
                            dispatch('notifications/store_notification', {
                                type: 'error',
                                message: 'Ocurrió un error al listar las sucursales disponibles, vuelva a intentarlo.'
                            }, {root: true});
                        })
        }
    }
}