import Api from './Api';

const END_POINT = 'products';

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'products-paginated'
                else
                    url = END_POINT
            } else {
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request);
    },
    async store(product) {
        return await Api.post(`${END_POINT}`, product);
    },
    async show(product) {
        return await Api.get(`${END_POINT}/${product.id}`);
    },
    async update(product) {
        return await Api.patch(`${END_POINT}/${product.id}`, product);
    },
    async change_status(product) {
        return await Api.patch(`${END_POINT}/${product.id}/change-status`, product);
    },
    async delete(product) {
        return await Api.delete(`${END_POINT}/${product.id}`, product);
    },
    get_prices(product) {
        return Api.get(`${END_POINT}/${product.product_id}/prices`);
    },
    download(filters = null) {
        let request = {
            params: filters
        }
        return Api.get(`${END_POINT}/donwload`, request);
    },
    async upload(product) {
        const config = {
            headers: {
                "content-type": 'multipart/form-data'
            }
        }
        return Api.post(`${END_POINT}/upload`, product, config);
    }
}