import Api from './Api'

const END_POINT = 'sedes'

export default {
    async get_all(filters = null) {
        let request = {
            params: filters
        }
        return await Api.get(`${END_POINT}`, request)
    },
    async store(sede) {
        return await Api.post(`${END_POINT}`, sede)
    },
    async update(sede) {
        return await Api.patch(`${END_POINT}/${sede.id}`, sede)
    },
    async change_status(sede) {
        return await Api.patch(`${END_POINT}/${sede.id}/change-status`, sede)
    },
    async delete(sede) {
        return await Api.delete(`${END_POINT}/${sede.id}`, sede)
    }
}