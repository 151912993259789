import Api from './Api';

const END_POINT = 'units';

export default {
    async get_all(filters = null) {        
        let request = {
            params: filters
        }
        let url = ''
        if (filters != null) {
            console.log(filters)
            if (filters.paginated || filters.paginated != null || filters.paginated !== undefined) {
                if (filters.itemsPerPage != -1)
                    url = 'units-paginated'
                else
                    url = END_POINT
            }else{
                url = END_POINT;
            }
        } else {
            url = END_POINT;
        }
        return await Api.get(url, request)
    },
    async store(unit) {
        return await Api.post(`${END_POINT}`, unit)
    },
    async update(unit) {
        return await Api.patch(`${END_POINT}/${unit.id}`, unit)
    },
    async change_status(unit) {
        return await Api.patch(`${END_POINT}/${unit.id}/change-status`, unit)
    },
    async delete(unit) {
        return await Api.delete(`${END_POINT}/${unit.id}`, unit)
    }
}