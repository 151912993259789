import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios'
import store from './store/index'
import CxltToastr from 'cxlt-vue2-toastr'
import Vue2Filters from 'vue2-filters'
import permissions from './plugins/vue-permissions';
import VueSweetalert2 from 'vue-sweetalert2';
import HighchartsVue from "highcharts-vue";

//import 'bootstrap/dist/css/bootstrap.css';
//import Chart from 'chart.js'; //graficos

import { sync } from 'vuex-router-sync'

import "@/assets/css/custom-vuetify.css"
import "@/assets/css/status.css"
import "@/assets/css/custom-forms.css"
import "@/assets/css/custom.css"
import "@/assets/css/custom-dialog.css"

import "@/assets/css/sign-in.css";
import "@/assets/css/custom-animation.css";
import "@/assets/css/animation-color.css";
import "@/assets/css/custom-fonts.css";

import "@/assets/fontawesome/css/all.css";
import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css';

import 'sweetalert2/dist/sweetalert2.min.css';

//Vue.use(Chart); //graficos
//import "boxicons";

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(Vue2Filters)
Vue.use(permissions);
Vue.use(VueSweetalert2);
Vue.use(HighchartsVue);

var toastrConfigs = {
  position: 'top right',
  showDuration: 1000,
  showMethod: 'swing',
  hideDuration: 1000,
  hideMethod: 'fadeOut',
  timeOut: 3000,
  closeButton: true,
  progressBar: true
}
Vue.use(CxltToastr, toastrConfigs)

sync(store, router)

//const moment = require('moment')
const moment = require('moment-timezone');
moment.tz.setDefault("America/Lima");
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})

require('@/plugins/BarCodeSupport/Trigger')

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
